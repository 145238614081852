import * as t from '../mutations'
import http from '@/http'
import Vue from 'vue'
import { sortBy } from 'lodash/collection'
import _ from 'lodash'
import router from '@/routes'
import { humanize } from '@/common/modules/strings'
import {
  completeInteraction,
  createOrFindClientInteractionLog,
} from '@/common/modules/clientInteractionLog'
import {
  DOMESTIC,
  ENTITY_TYPES_WITHOUT_BOI_FILING,
  FILINGS_PACKAGED_WITH_BOI_FILING,
  GENERAL_OVERSEAS_SHIPPING_FEE_NAMES,
  PRODUCTS_WITH_SHIPPING,
} from '@/common/modules/constants'

const PRODUCT_PATH_PARTS_BY_KIND = {
  subscription_bundle:         'subscription_bundles',
  registered_agent_product:    'registered_agent_products',
  simple_product:              'simple_products',
  filing_product:              'filing_products',
  product_bundle:              'product_bundles',
  supply_and_consult_product:  'supply_and_consult_products',
}

const getDefaultState = () => {
  return {
    products: {
      registered_agent_product: [],
      simple_product: [],
      filing_product: [],
      supply_and_consult_product: [],
      product_bundle: [],
      subscription_bundle: [],
    },
    productsLoaded: false,
    productsLoading: false,
    context: {
      cartTypeScope: null,
      companyId: null,
      jurisdictionId: null,
      productKind: null,
      selectedJurisdictions: null,
    },
    optionalItemSetup: {
      compliance: {
        enabled: true,
        kind: 'simple_product',
        predicate: {
          category: 'compliance',
        },
        parentProductKind: 'registered_agent_product',
      },
      changeOfAgent: {
        enabled: false,
        kind: 'filing_product',
        predicate: {
          filing_name: 'change registered agent',
        },
        parentProductKind: 'registered_agent_product',
      },
      lawOnCall: {
        enabled: true,
        kind: 'simple_product',
        predicate: {
          category: 'law-on-call',
        },
        parentProductKind: 'registered_agent_product',
      },
    },
    hireUsOptionalItemSetup: {
      compliance: {
        enabled: true,
        kind: 'simple_product',
        predicate: {
          category: 'compliance',
        },
        parentProductKind: 'registered_agent_product',
      },
      changeOfAgent: {
        enabled: false,
        kind: 'filing_product',
        predicate: {
          filing_name: 'change registered agent',
        },
        parentProductKind: 'registered_agent_product',
      },
      monitoring: {
        enabled: true,
        kind: 'simple_product',
        predicate: {
          category: 'monitoring',
        },
        parentProductKind: 'filing_product',
      },
    },
    cartItems: [],
    agreementAccepted: false,
    processingCheckout: false,
    checkoutOrder: null,
    checkoutInvoice: null,
    checkoutDisabled: false,
    currentProduct: null,
    currentBundleItems: null,
  }
}
const STATE = getDefaultState()

const GETTERS = {
  selectedPaymentMethod: (_state, getters, _rootState, rootGetters) => rootGetters['paymentMethods/selectedPaymentMethod'],
  optionalItemSetup: state => state.optionalItemSetup,
  hireUsOptionalItemSetup: state => state.hireUsOptionalItemSetup,
  monitoringOptionalItemSetup: state => state.monitoring,
  allProducts: state => Object.values(state.products).reduce((m, a) => m.concat(a), []),
  products: (state, getters) => state.products[getters.context.productKind],
  productsLoaded: state => state.productsLoaded,
  productsLoading: state => state.productsLoading,
  bundles: state => state.products.bundles,
  subscriptionBundles: state => state.products.subscription_bundle,
  context: state => state.context,
  entityTypeId: (state, _getters, _rootState, rootGetters) => {
    return rootGetters['companies/find'](state.context.companyId)?.entity_type_id
  },
  domesticJurisdiction: (state, _getters, _rootState, rootGetters) => {
    return rootGetters['companies/find'](state.context.companyId)?.domestic_registration?.jurisdiction
  },
  findProduct: (state, getters) => id => getters.allProducts.find(p => p.id === id),
  cartItems: state => state.cartItems,
  productItems: getters => getters.cartItems.filter(ci => ci.product_type !== 'PromoCode'),
  promoCodes: getters => getters.cartItems.filter(ci => ci.product_type === 'PromoCode'),
  cartItemsLength: (state, getters) => {
    let count = getters.cartItems.length

    if (getters.cartItems.find(ci => ci.display_info?.product_category === 'free form a company')) {
      // We automatically include the 6 month RA item for free formations and manually set the display, this keeps the count working in that case.
      count ++
    }

    return count
  },
  cartBundleItems: (_state, getters) => {
    return getters.cartItems
      .filter(item => item?.display_info?.products?.length && item.product_type === 'SubscriptionBundle')
  },
  cartProductBundleItems: (_state, getters) => {
    return getters.cartItems
      .filter(item => item?.display_info?.product_kind === 'product_bundle')
  },
  cartItemsByKind: (state, getters) => kind => {
    return state.cartItems.filter(item => getters.basketKindToProductKind(item.product_type) === kind)
  },
  cartItemsTotal: (state, getters) => state.cartItems.reduce((acc, item) =>
    acc + (getters.productIsBundle(item) ?
      getters.productBundleTotal(item, true) :
      (item.display_info.is_free_trial ? 0 : item.total)), 0
  ),
  legalCartItemsTotal: state =>
    state.cartItems.filter(item => item.product_type === 'LegalSpec').reduce((acc, item) =>
      acc + (parseFloat(item.data?.estimated_cost.replace('$', '')) || 0), 0),
  cartItemsAreRenewable: (state, getters) => state.cartItems.some(cartItem => getters.findProduct(cartItem.product_id)?.renewable === true),
  cartEmpty: state => state.cartItems.length === 0,
  optionalItemEnabled: state => name => state.optionalItemSetup[name].enabled,
  findCartItem: (_state, getters) => productId => getters.cartItems.find(item => item.product_id === productId),
  findCartItemById: (_state, getters) => cartItemId => getters.cartItems.find(item => item.id === cartItemId),
  findCartItemByFilingMethodId: (_state, getters) => filingMethodId => getters.cartItems.find(item => item.filing_method_id === filingMethodId),
  optionalItemsInCart: (_state, getters) => getters.cartItems.filter(item => item.optional_add_on === true),
  allFormsValid: (state, getters) => getters.cartEmpty,
  agreementAccepted: state => state.agreementAccepted,
  processingCheckout: state => state.processingCheckout,
  checkoutDisabled: state => state.checkoutDisabled,
  currentProduct:  state => state.currentProduct,
  currentBundleItems: state => state.currentBundleItems,
  productBundleTotal: (_state, _getters) => (bundle_item, includeFilingMethodCost = false) => {
    let bundleTotal = bundle_item.total
    const price = Object.values(bundle_item.data).reduce((acc, product) => {
      const trialPrice = product.is_free_trial ?
        product.price :
        0
      const filingMethodCost = includeFilingMethodCost ?
        product?.filing_method?.cost || 0 :
        0
      return acc + filingMethodCost - trialPrice
    }, bundleTotal)
    //TODO: Remove this hack
    if (bundle_item.title === "Business Identity bundle" && price === 61) {
      return 48.80
    }
    return price
  },

  cartProductsByKind: state => kind => {
    return state.cartItems
      .map(item => ({ cartItemId: item.id, ...state.products[kind].find(p => p.id === item.product_id) }))
      .filter(item => item.id)
  },
  cartItemWithShipping: (state, getters) => getters.cartItems.find(item => PRODUCTS_WITH_SHIPPING.includes(item.title.toLowerCase())),
  cartItemShippingAddressIsForeign: (_state) => (cartItem) => {
    const country = cartItem?.data?.address?.country
    return (country ? !DOMESTIC.includes(country?.trim().toLowerCase()) : false)
  },
  cartItemWithForeignShippingAddress: (_state, getters) => getters.cartItems.find(item => {
    const address = item?.data?.address
    return address?.hasOwnProperty('country') && !DOMESTIC.includes(address.country.toLowerCase())
  }),
  cartItemByCategory: (_state, getters) => category => {
    return getters.cartItems.find(i =>
      i.display_info.product_category === category
    )
  },
  cartItemByCategoryAndJurisdiction: (_state, getters) => (category, jurisdiction) => {
    return getters.cartItems.find(item => item.display_info.product_category === category &&
      item.display_info.jurisdiction_abbreviation === jurisdiction.abbreviation)
  },
  cartItemsByCategoryAndJurisdiction: (_state, getters) => (category, jurisdiction) => {
    return getters.cartItems
      .filter(item => item.display_info.product_category === category
        && ((item.display_info.jurisdiction_abbreviation === jurisdiction.abbreviation)
        || !item.display_info.jurisdiction_abbreviation))
  },
  cartItemExistsByCategoryAndJurisdiction: (_state, getters) => (category, jurisdiction) => {
    return getters.cartItemsByCategoryAndJurisdiction(category, jurisdiction).length
  },
  registeredAgentProductByJurisdiction: state => jurisdiction => {
    return state.products.registered_agent_product.find(product =>
      product.jurisdiction === jurisdiction
    )
  },
  subscriptionBundlesByJurisdiction: state => jurisdiction => {
    return state.products.subscription_bundle.filter(product =>
      product.jurisdiction_id === jurisdiction.id
    )
  },
  productsByKind: (state, getters) => kind => {
    return getters.allProducts.filter(p => p.kind === kind)
  },
  productIsBundle: (_state, getters) => product => {
    return ['subscription_bundle', 'product_bundle'].includes(getters.basketKindToProductKind(product.kind))
      || ['subscription_bundle', 'product_bundle'].includes(getters.basketKindToProductKind(product.product_type))
  },
  findProductBy: state => (kind, predicate) => {
    return state.products[kind].find(product => {
      return Object.entries(predicate).reduce((acc, [key, val]) => {
        if (key.includes('.')) {
          const [firstLevelKey, secondLevelKey] = key.split('.')
          return acc && product[firstLevelKey] && product[firstLevelKey][secondLevelKey] === val
        }
        return acc && product[key] === val
      }, true)
    })
  },
  findProductsBy: state => (kind, predicate) => {
    return state.products[kind].filter(product => {
      return Object.entries(predicate).reduce((acc, [key, val]) => acc && product[key] === val, true)
    })
  },
  findTaxIdProduct: (state) => (withSSN) => {
    const taxIdProducts = state.products.filing_product.filter(product =>
      product.filing_name === 'tax id'
    )
    const ssnFilterFunction = product => (product.name.includes('No SSN') ||
      product.name.includes('without SSN'))

    return taxIdProducts.find(product => {
      return withSSN ? !ssnFilterFunction(product) : ssnFilterFunction(product)
    })
  },
  overseasShippingFeeProduct(state) {
    return state.products['simple_product'].filter(product => GENERAL_OVERSEAS_SHIPPING_FEE_NAMES.includes(product.name.toLowerCase()))[0]
  },
  ineligibleToOrderSSNTaxId: (state, getters) => {
    const nonSsnTaxIdProduct = state.products.filing_product.find(product =>
      product.filing_name === 'tax id' &&
      product.filing_sub_name === 'no-ssn tax id'
    )

    return nonSsnTaxIdProduct ? getters.productIdInCart(nonSsnTaxIdProduct.id) : false
  },
  ineligibleToOrderNonSSNTaxId: (state, getters) => {
    const ssnTaxIdProduct = state.products.filing_product.find(product =>
      product.filing_name === 'tax id' &&
      product.filing_sub_name === 'ssn tax id'
    )

    return ssnTaxIdProduct ? getters.productIdInCart(ssnTaxIdProduct.id) : false
  },
  findFreeTaxIdProduct: (state) => {
    return state.products.filing_product.find(fp => fp.filing_name === 'free tax id')
  },
  optionalItems: (state, getters) => getters.allProducts.filter(item =>
    item.kind === 'simple_product' && !item.renewable
  ),
  //This is necessary for the inflection used in shopping_basket_item model (.classify)
  basketKindToProductKind: () => productKind => {
    if (productKind === undefined) { return null }
    if (productKind.match(/(registered).*(agent)/i))         { return 'registered_agent_product'   }
    else if (productKind.match(/simple/i))                   { return 'simple_product'             }
    else if (productKind.match(/filing/i))                    { return 'filing_product'              }
    else if (productKind.match(/(subscription).*(bundle)/i)) { return 'subscription_bundle'        }
    else if (productKind.match(/(product).*(bundle)/i))      { return 'product_bundle'             }
    else if (productKind.match(/supplyandconsult/i))         { return 'supply_and_consult_product' }
  },
  deduceProductJurisdiction: (_state, _getters, _rootState, rootGetters) => product => {
    return rootGetters['jurisdictions/findById'](product?.jurisdiction?.id)
      || rootGetters['jurisdictions/findById'](product?.jurisdiction_id)
      || rootGetters['jurisdictions/findByAbbreviation'](product?.data?.state)
      || rootGetters['jurisdictions/findByName'](product?.jurisdiction)
      || rootGetters['jurisdictions/findById'](product?.filing_methods?.find(fm =>
        fm.id = product.filingMethodId)?.jurisdiction_id
      )
      || rootGetters['jurisdictions/findByName']("Federal")
  },
  deduceProductCategory: () => product => {
    if (product.kind === 'registered_agent_product') { return 'registered-agent' }
    else if (product.kind === 'subscription_bundle') { return 'subscription-bundle' }
    else { return product.filing_name || product.category }
  },
  deduceCartItemJurisdiction: (_state, _getters, _rootState, rootGetters) => cartItem => {
    if (cartItem?.display_info?.jurisdiction_abbreviation == null) { return null }
    else { return rootGetters['jurisdictions/findByAbbreviation'](cartItem?.display_info?.jurisdiction_abbreviation) }
  },
  productIsFreeMailForwarding: () => product => {
    return product?.subcategory === 'free_limited_use'
  },
  productDeepMatchCartItem: (_state, getters) => (cartItem, product) => {
    const distinctProductCategoryTypes = [
      'form a company', 'free form a company', 'tax id', 'virtual-phone', 'merchant-service',
      'operating agreement', 'corporate bylaws', 'monitoring','corporate-book-and-seal', 'business-email', 'business-website-hosting', 'domain-ssl',
    ]

    if (product.id === cartItem.product_id || product.product_id === cartItem.product_id) {
      const productJurisdictionId = getters.deduceProductJurisdiction(product)?.id
      const productLocalJurisdictionId = product?.localJurisdictionId
      const productCategory = getters.deduceProductCategory(product)
      const itemJurisdictionId = getters.deduceCartItemJurisdiction(cartItem)?.id
      const itemLocalJurisdictionId = cartItem?.display_info?.local_jurisdiction_id
      const itemCategory = cartItem?.display_info?.product_category
      if (productCategory === itemCategory) {
        if (productLocalJurisdictionId) {
          return productLocalJurisdictionId === itemLocalJurisdictionId
        }
        else {
          // Can't make more than one form a the distinct filings, regardless of jurisdiction.
          return productJurisdictionId === itemJurisdictionId ||
            distinctProductCategoryTypes.includes(productCategory)
        }
      }
    }
    return false
  },
  formationProductInCart: (_state, getters) => {
    const formations = ['vehicle form a company', 'form a company', 'free form a company']
    return !!getters.cartItems.find(i =>
      formations.includes(i.display_info.product_category)
    ) || getters.filingProductInCartBundle(formations)
  },
  raServiceInCart: (_state, getters) => {
    return getters.cartItems.some(cartItem =>
      cartItem?.product_type === 'RegisteredAgentProduct'
    ) || !!getters.bundleCartItemsByCategoryAndJurisdiction('registered-agent')?.length
  },
  sCorpInCart: (_state, getters) => {
    return getters.cartItems.find(cartItem =>
      cartItem?.display_info?.product_category === 's corp'
    )
  },
  productByTitleInCart: (_state, getters) => (productName) => {
    return getters.cartItems.find(cartItem => cartItem?.title === productName)
  },
  boiFilingInCart: (_state, getters) => {
    return getters.cartItems.find(cartItem =>
      cartItem?.display_info?.product_category === 'beneficial ownership information report'
    )
  },
  filingProductCategoryCartItems: (state) => (category) => {
    return state.cartItems.filter(cartItem => cartItem?.display_info?.product_category === category &&
      cartItem?.display_info?.product_kind  === 'filing_product')
  },
  raServiceActiveOrInCart: (_state, getters, _rootState, rootGetters) => {
    return getters.raServiceInCart || !!rootGetters['companies/activeRAServices']?.length
  },
  productDeepMatchCartItemBundledProduct: (_state, getters) => (bundledProduct, product) => {
    if (product.id === bundledProduct.id || product.id === bundledProduct.product_id) {
      const productJurisdiction = getters.deduceProductJurisdiction(product)
      const productCategory = getters.deduceProductCategory(product)
      const bundledProductJurisdiction = getters.deduceProductJurisdiction(bundledProduct)
      const bundledProductCategory = getters.deduceProductCategory(bundledProduct)

      // Need to use weak comparison to account for falsey values when comparing product with bundledProduct category and jurisdiction.
      if (productCategory === bundledProductCategory) {
        // Can't make more than one form a company filing, regardless of jurisdiction.
        return productCategory === 'form a company' ||
          productJurisdiction === bundledProductJurisdiction
      }
    }
    return false
  },
  findExactCartItemByProduct: (_state, getters, _rootState) => (product) => {
    const similarCartItems = getters.cartItemsByKind(product?.kind)
    if (similarCartItems.length > 0) {
      for (const cartItem of similarCartItems) {
        if (getters.productDeepMatchCartItem(cartItem, product)) {
          return cartItem
        }
      }
    }
    return false
  },
  findCartBundleByProductInBundle: (_state, getters) => product => {
    for (const bundle of getters.cartBundleItems) {
      if (bundle?.display_info?.products.some(cartBundleProduct => {
        return getters.productDeepMatchCartItemBundledProduct(cartBundleProduct, product)
      })) {
        return bundle
      }
    }
    for (const bundle of getters.cartProductBundleItems) {
      if (Object.values(bundle?.data || []).some(cartBundleProduct => {
        return getters.productDeepMatchCartItemBundledProduct(cartBundleProduct, product)
      })) {
        return bundle
      }
    }
  },
  productDescriptiveString: (_state, getters) => product => {
    const deducedCategory = getters.deduceProductCategory(product)
    const deducedJur = getters.deduceProductJurisdiction(product)
    return deducedJur?.state_province_region ?
      `${humanize(deducedCategory)} in ${humanize(deducedJur.state_province_region)}`
      : `${humanize(deducedCategory)}`
  },
  productTypeInCart: (_state, getters) => productType => {
    return getters.cartItems.some(cartItem => cartItem.product_type === productType)
  },
  findCartItemByProductId: (_state, getters) => productId => {
    return getters.cartItems.find(cartItem => cartItem.product_id === productId)
  },
  productIdInCart: (_state, getters) => productId => {
    return getters.cartItems.some(cartItem => cartItem.product_id === productId)
  },
  productIdInCartBundle: (_state, getters) => productId => {
    return getters.cartProductBundleItems.some(bundle =>
      Object.keys(bundle.data).some(key => key === productId)
    )
  },
  bundleInCartWithFilingProduct: (_state, getters) => filingName => {
    return getters.cartProductBundleItems.find(bundle =>
      Object.values(bundle.data).find(value => value.filing_name === filingName)
    )
  },
  filingProductInCartBundle: (_state, getters) => filingNames => {
    if (!Array.isArray(filingNames)) filingNames = [filingNames]
    return getters.cartProductBundleItems.some(bundle =>
      Object.values(bundle.data).some(value => filingNames.includes(value.filing_name))
    )
  },
  bundleCartItemsByCategoryAndJurisdiction: (_state, getters) => (productCategory, jurisdiction = null) => {
    const productJurisdiction = jurisdiction?.state_province_region || jurisdiction
    let filteredItems = getters.cartProductBundleItems.filter(bundle =>
      Object.values(bundle.data).some(value =>
        value.category === productCategory
      )
    )

    if (productJurisdiction) {
      filteredItems = filteredItems.filter(bundle =>
        Object.values(bundle.data).some(value =>
          value.jurisdiction === productJurisdiction
        )
      )
    }

    return filteredItems
  },
  renewalService: state => {
    return state.products.simple_product.find(product => product.name === 'Renewal Service')
  },
  boiFiling: state => {
    return state.products.filing_product.find(product =>
      product.filing_name === 'beneficial ownership information report'
    )
  },
  renewalServiceWithJurisdictionInCart: (_state, getters) => jurisdiction => {
    return getters.cartItems.some(cartItem =>
      cartItem?.display_info?.product_category  === 'compliance' &&
      cartItem?.data?.state === jurisdiction
    )
  },
  isFormationFiling: (_state, _getters) => (filingName) => {
    return ['vehicle form a company', 'form a company', 'free form a company'].includes(filingName)
  },
  isMailForwardingInCartForJurisdiction: (_state, getters) => (product, jurisdiction) => {
    return getters.deduceProductCategory(product) === 'mail-forwarding' &&
      getters.cartItemExistsByCategoryAndJurisdiction("mail-forwarding", jurisdiction)
  },
  ghostMode: (_state, _getters, _rootState, rootGetters) => rootGetters['stageline/ghostMode'],
  companyHasSuspendedPhoneService: (_state, _getters, rootGetters) => {
    const suspendedServices = rootGetters.companies.suspendedServices
    return suspendedServices.some(suspendedService => suspendedService.type === 'virtual-phone')
  },
}

const ACTIONS = {
  async removeFromCart({ getters, dispatch }, { id }) {
    if (getters.ghostMode) return

    await http.delete(`client/companies/${getters.context.companyId}/shopping_basket_items/${id}`)
    await dispatch('loadCartItems')
  },
  async removeProductIdFromCart({ getters, dispatch }, productId) {
    if (getters.ghostMode) return
    const cartItemId = getters.findCartItemByProductId(productId)
    if (cartItemId) dispatch('removeFromCart', cartItemId)
  },

  async clearCart({ getters, dispatch }) {
    if (getters.ghostMode) return

    for (const item of getters.cartItems) {
      // Child cart items can't be directly deleted
      if (!item.parent_item_id) {
        await http.delete(`client/companies/${getters.context.companyId}/shopping_basket_items/${item.id}`)
      }
    }

    await dispatch('loadCartItems')
  },

  async addToCart({ commit, getters, dispatch, _rootState, rootGetters }, product) {
    if (getters.ghostMode) return

    const deducedProductCategory = getters.deduceProductCategory(product)
    const deducedProductJur = getters.deduceProductJurisdiction(product)
    // Case 1: Product or bundle is already in cart.
    if (getters.findExactCartItemByProduct(product)) {
      alert(`Note: You can only add one ${product.name} service to your cart.`)
      return false
    }
    // Disallow adding multiple mail forwarding services for same jurisdiction
    if (getters.isMailForwardingInCartForJurisdiction(product, deducedProductJur)) {
      alert(`Note: You can only add one mail-forwarding service per jurisdiction to your cart.`)
      return false
    }
    // Case 2: Product is member of a bundle already in cart.
    const parentBundleAlreadyInCart = getters.findCartBundleByProductInBundle(product)
    if (parentBundleAlreadyInCart) {
      const parentBundleProduct = getters.findProduct(parentBundleAlreadyInCart.product_id)
      if (product?.filing_name === 'form a company') {
        alert(`Note: A Form a Company filing is already included in the (${parentBundleAlreadyInCart.title}) in your Cart.`)
      } else if (parentBundleProduct?.product_categorization.subcategory === 'business_identity') {
        alert(`Note: The item "${product.name}" is already part of the (${parentBundleAlreadyInCart.title}) in your Cart.`)
      } else {
        alert(`Note: The item "${getters.productDescriptiveString(product)}" is already part of the (${parentBundleAlreadyInCart.title}) in your Cart.`)
      }
      return false
    }
    // Case 3: Product is a bundle containing a member already in cart.
    if (getters.productIsBundle(product)) {
      let count = 0
      for (const member of product.products) {
        member.jurisdiction_id = product.jurisdiction_id
        const bundleMemberAlreadyInCart = getters.findExactCartItemByProduct(member)
        if (bundleMemberAlreadyInCart) {
          await dispatch('removeFromCart', bundleMemberAlreadyInCart)
          count++
        }
      }
      if (count > 0) {
        alert(`Note: Your Cart already contains ${count} of the items in this package, which will now be` +
          ` included with the bundled version.`)
      }
    }
    // Case 4: Product or bundle is already active on account.
    const productsActiveOnAccount = rootGetters['companies/activeServicesByCategoryAndJurisdiction'](deducedProductCategory, deducedProductJur)
    let hasProductActiveOnAccount = productsActiveOnAccount?.length > 0
    //bypass case if upgrading/downgrading mail-forwarding
    if (deducedProductCategory === 'mail-forwarding' && hasProductActiveOnAccount) {
      if (!productsActiveOnAccount.some(item => item.product.id === product.id)) {
        hasProductActiveOnAccount = false
      }
    }
    // bypass case if item is phone service
    if (deducedProductCategory === 'virtual-phone' && hasProductActiveOnAccount) {
      hasProductActiveOnAccount = false
    }
    //bypass case if item is in BIZID bundle
    if (['business-domain', 'business-email', 'business-website-hosting', 'domain-ssl'].includes(deducedProductCategory)) {
      hasProductActiveOnAccount = false
    }
    if (hasProductActiveOnAccount) {
      alert(`Note: The item (${getters.productDescriptiveString(product)}) is already active on your account.`)
      return false
    }
    // Case 5: Bundle contains a product already active on account and isn't BIZID bundle.
    if (getters.productIsBundle(product) && product.name !== 'Business Identity') {
      for (const member of product.products) {
        let memberProduct = member?.product || member

        let deducedMemberCategory = getters.deduceProductCategory(memberProduct)
        let deducedMemberJur = getters.deduceProductJurisdiction(member)

        let memberActiveOnAccount = rootGetters['companies/activeServicesByCategoryAndJurisdiction'](deducedMemberCategory, deducedMemberJur)[0]
        if (memberActiveOnAccount) {
          const humanizedDescription = deducedMemberJur?.state_province_region ?
            `${humanize(deducedMemberCategory)} in ${humanize(deducedMemberJur.state_province_region)}`
            : `${humanize(deducedMemberCategory)}`

          alert(`Note: This package includes ${humanizedDescription}, a product you already have active on your account. Call us to inquire about purchasing this package.`)
          return false
        }
      }
    }

    // Case 6: Product is Formation Filing and Formation Filing already exists on account
    const isFormationFiling = getters.isFormationFiling(product?.filing_name)
    if (isFormationFiling) {
      const alreadyHasFormation = !!rootGetters['companies/formationFiling'] || false

      if (alreadyHasFormation) {
        alert('Note: There is already an active Formation Filing on your account.')
        return false
      } else if (getters.formationProductInCart) {
        alert('Note: There is already a Formation Filing in your Cart.')
        return false
      }
    }

    // Case 7: prevent duplicate Overseas Shipping Fee when product requires physical shipping
    // Only check for relevant products and websites that have a overseas shipping fee product associated
    if (product?.name) {
      if (PRODUCTS_WITH_SHIPPING.includes(product?.name.toLowerCase()) && getters.overseasShippingFeeProduct) {
        const overseasShippingFeeAlreadyInCart = getters.findExactCartItemByProduct(getters.overseasShippingFeeProduct)
        if (overseasShippingFeeAlreadyInCart) await dispatch('removeFromCart', overseasShippingFeeAlreadyInCart)
      }
    }

    // Case 8: Product is phone service or bundle that includes phone service and account already has suspended phone service
    const isPhoneService = product?.category === 'virtual-phone'
    const isVirtualOffice = (product?.name) ? product?.name.toLowerCase().includes('virtual office') : false
    if ((isPhoneService || isVirtualOffice) && getters.companyHasSuspendedPhoneService) {
      alert('This account has a suspended phone service, adding another is not allowed.')
      return false
    }

    const product_kind = product.kind || getters.context.productKind
    const displayInfo = {}
    let data = product.data || {}

    if (product.products) {
      // Hack sub-product data for bundles into displayInfo hash for use on (hopefully only) frontend
      const allowed_keys = [
        'category', 'description', 'duration', 'filing_name', 'id', 'jurisdiction', 'kind', 'name',
        'price', 'renewable', 'subcategory',
      ]
      displayInfo['products'] = product.products.map(p => _.pick(p, allowed_keys))
    }

    /*
      TL;DR: For now, merge CompanyAgencyResource subkeys into one `data` key to be sent on
      ShoppingBasketItem creation for eventual transfer to OrderItem/Company/Registration details.
      It's nice to have the distinction between __default__, __company__, and __registration__ data
      in the C.A.R. processed form details, but carrying that structure into the API Checkout system would
      be too fundamental of a modification to the Checkout system and would require testing we don't
      really have the luxury for at the moment. So for now, merge all subkeys into `data`. Will be
      handy to have the distinction in the CompaniesAgencyResource.processedFormData, though, since
      it's a new model/column and there are no legacy interfaces to it that need updating.
    */
    // TODO Make sure we do this same thing for formation filings that are part of a bundle.
    const formattedProcessedFormData = rootGetters['stagelineSchemaForm/formattedProcessedFormData']
    if (isFormationFiling && !_.isEmpty(formattedProcessedFormData)) {
      data = _.merge(data, formattedProcessedFormData)
    }

    const basketItemData = {
      product_id: product.id,
      product_type: product_kind,
      filing_method_id: product.filingMethodId,
      parent_item_id: product.parentItemId,
      data: data,
      display_info: displayInfo,
      cart_type_scope: getters.context.cartTypeScope,
      optional_add_on: !!product?.optionalAddOn,
    }

    // We set totalCost on the product in the FilingOptions.
    if (product.filing_name === 'free form a company') basketItemData.total = product.totalCost

    const response = await http.post(`client/companies/${getters.context.companyId}/shopping_basket_items`, basketItemData)

    commit(t.CART_ITEMS_LOADED, response.data.result)

    if (product.skipResolveOptionalItems) return true

    dispatch('resolveOptionalItems', {})
    return true
  },

  enableBoiAddOn ({ getters, rootGetters }, product) {
    const accountType = rootGetters['account/accountType']
    const currentCompany = rootGetters['companies/currentCompany']
    const excludedAccountType = 'wholesale-registered-agent'

    return (getters.boiFiling &&
      FILINGS_PACKAGED_WITH_BOI_FILING.includes(product?.filing_name) &&
      accountType !== excludedAccountType &&
      !ENTITY_TYPES_WITHOUT_BOI_FILING.includes(currentCompany?.entity_type))
  },

  async fetchDbaAddOns({ getters }, method) {

    if (getters.ghostMode) return

    const response = await http.get(`filings/dependent_filings`, {
      params: {
        filing_id: method.filing_id,
        filing_method_id: method.id,
        company_id: getters.context.companyId,
      },
    })
    return response.data.result
  },

  async addBundleToCart({ commit, getters, dispatch, rootGetters }, { bundle, removeItemsContainedInBundle = true }) {
    if (getters.ghostMode) return

    const bundleContainsPhoneService = Object.entries(bundle.data).some(([_id, item]) => {
      return item.name === 'Phone Service'
    })

    if (bundleContainsPhoneService && getters.companyHasSuspendedPhoneService) {
      alert('This account has a suspended phone service, adding a bundle that contains phone service is not allowed.')
      return false
    }

    const activeProductBundles = rootGetters['companies/activeProductBundles']

    if (activeProductBundles?.some(productBundle => productBundle.id === bundle.id)) {
      alert(`Note: This company has already purchased this bundle. Contact us if you require support or have any questions.`)
      return false
    }

    // Remove standalone products in cart that are in the product bundle being added.
    // For some cases like the Formation Bundle in the PDF viewer the items already contained in the
    // bundle are removed after the bundle is added so don't need to remove here.
    if (removeItemsContainedInBundle) {
      let count = 0
      for (const product of Object.values(bundle.data)) {
        const bundleMemberAlreadyInCart = getters.findExactCartItemByProduct(product)
        if (bundleMemberAlreadyInCart) {
          await dispatch('removeFromCart', bundleMemberAlreadyInCart)
          count++
        }
      }
      if (count > 0) {
        alert(`Note: Your Cart already contains ${count} of the items in this package, which will now be` +
          ` included with the bundled version.`)
      }
    }

    const data = {
      product_type: 'product_bundle',
      product_id: bundle.id,
      data: bundle.data,
    }

    const response = await http.post(`client/companies/${getters.context.companyId}/shopping_basket_items`, data)
    commit(t.CART_ITEMS_LOADED, response.data.result)

    if (bundle?.skipResolveOptionalItems) return true

    dispatch('resolveOptionalItems', {})
    return true
  },

  async addPromoCodeToCart({ dispatch, getters }, code) {
    if (getters.ghostMode) return
    if (!code) return false

    const company_id = getters.context.companyId
    const data = { code, company_id }

    try {
      const response = await http.post('client/promo_codes/apply', data)
      const { success } = response.data

      if (success) await dispatch('loadCartItems')

      return response.data
    } catch {
      return { success: false, response: { error: "An unexpected error occurred." } }
    }
  },

  async addFreeTaxIdToCart({ commit, getters }, parentItem) {
    if (getters.ghostMode) return

    const freeTaxIdProduct = getters.findFreeTaxIdProduct
    const filingMethodId = freeTaxIdProduct.filing_methods.find(fm => fm.purchasable).id

    const data = {
      product_id: freeTaxIdProduct.id,
      parent_item_id: parentItem?.id,
      product_type: freeTaxIdProduct.kind,
      filing_method_id: filingMethodId,
    }

    const response = await http.post(`client/companies/${getters.context.companyId}/shopping_basket_items`, data)
    commit(t.CART_ITEMS_LOADED, response.data.result)
  },

  async checkForRACartItemAndRemove({ getters, dispatch }, jurisdiction) {
    const cartItem = getters.findCartItem(getters.registeredAgentProductByJurisdiction(jurisdiction.state_province_region)?.id)
    if (cartItem) await dispatch('removeFromCart', { id: cartItem.id })
  },

  async addRegisteredAgentAddOn ({ getters, dispatch }, product) {
    const jurisdiction = product.jurisdiction
    const raProduct = getters.registeredAgentProductByJurisdiction(jurisdiction.state_province_region)
    const raCartItem = getters.findCartItem(getters.registeredAgentProductByJurisdiction(jurisdiction.state_province_region)?.id)

    if (raCartItem) {
      await dispatch('setCartItemParentId', {
        id: raCartItem.id,
        parentItemId: product.parentItemId,
      })
    } else if (raProduct) {
      await dispatch('addToCart', {
        ...raProduct,
        kind: 'registered_agent_product',
        parentItemId: product.parentItemId,
        skipResolveOptionalItems: true,
      })
    }
  },

  async setCartItemParentId ({ getters, dispatch }, cartItem) {
    if (getters.ghostMode) return

    const response = await http.patch(`client/companies/${getters.context.companyId}/shopping_basket_items/${cartItem.id}`, {
      shopping_basket_item: {
        parent_item_id: cartItem.parentItemId,
      },
    })

    if (response.data.success) await dispatch('loadCartItems')
  },

  async loadCartItems({ commit, getters }) {
    const response = await http.get(`client/companies/${getters.context.companyId}/shopping_basket_items`, {
      params: { cart_type_scope: getters.context.cartTypeScope },
    })

    commit(t.CART_ITEMS_LOADED, response.data.result)
  },

  async checkForBundleFreeTrialsAndCalculateTotal({ getters, _state, dispatch, commit }, { productBundle }) {
    commit(t.RESET_CURRENT_BUNDLE_ITEMS, {})
    productBundle.price = productBundle.original_price

    const productCategories = productBundle.products
      .map(x => x.category)
      .filter(category => category !== 'filing')

    const trialEligibilityBatch = productCategories.length ?
      await dispatch('freeTrials/determineTrialEligibilityBatch',
        {
          company_id: getters.context.companyId,
          product_categories: productCategories,
        },
        { root: true }
      ) : {}

    productBundle.products.forEach(product => {
      product.is_free_trial = trialEligibilityBatch[product.category] || false
    })

    for (const product of productBundle.products) {
      productBundle = await dispatch('setBundleProduct', {
        productBundle: productBundle,
        product: product,
      })
    }

    return productBundle
  },
  async setBundleProduct({ _getters, commit, _state }, { product, productBundle = {} }) {
    const trialPrice = product.is_free_trial ? product.price : 0
    productBundle.price = Math.max(productBundle.price - trialPrice, 0)

    const bundleItem = {
      product_id: product.id,
      name: product.name,
      kind: product.kind,
      price: product.price,
      is_free_trial: product.is_free_trial || false,
      is_included_in_bundle_cost: product.is_included_in_bundle_cost || false,
      data: {},
    }

    // Set product category to match whats set on shopping_basket_items records when these are added
    // individually and not as a bundle
    let productCategory = product.category
    if (product?.kind === 'filing_product') productCategory = product.filing_name
    else if (product?.kind === 'registered_agent_product') productCategory = 'registered-agent'
    bundleItem['category'] = productCategory

    if (product?.jurisdiction) bundleItem['jurisdiction'] = product.jurisdiction

    const filingMethod = product.filing_method

    if (filingMethod?.id) {
      bundleItem['product_option_id'] = filingMethod.id
      bundleItem['filing_method_id'] = filingMethod.id
      bundleItem['filing_method'] = {
        id: filingMethod.id,
        name: filingMethod.name,
        cost: filingMethod.cost,
        jurisdiction: filingMethod.jurisdiction,
      }
      bundleItem['filing_name'] = product.filing_name
    }
    if (product.parentItemId) bundleItem['parent_item_id'] = product.parentItemId

    commit(t.SET_CURRENT_BUNDLE_ITEMS, { productId: product.id, bundleItem: bundleItem })
    return productBundle
  },

  async fetchProduct({ getters }, { productKind, productCategory, productSubCategory, jurisdictionId }) {
    // Used to fetch a single product off of product kind, category and jurisdiction
    const kindPath = PRODUCT_PATH_PARTS_BY_KIND[productKind]

    if (!kindPath) return []

    const params   = {
      company_id: getters.context.companyId,
      jurisdiction_id: jurisdictionId,
    }

    switch(productKind) {
      case 'filing_product':
        params.entity_type_id = getters.entityTypeId
        params.filing_name    = productCategory

        if (productSubCategory) params.filing_sub_name = productSubCategory
        if (productCategory === 'register a company') delete params.jurisdiction_id
        break
      case 'simple_product':
        params.categories = [productCategory]
        break
      case 'product_bundle':
        params.name = productCategory
        break
    }

    return new Promise(async (resolve)  => {
      await http.get(`client/products/${kindPath}`, { params }).then(response => {
        resolve(response.data.result)
      })
    })
  },

  async loadRAProducts({ commit, getters }) {
    const results = await http.get(`client/products/registered_agent_products`, {
      params: {
        company_id: getters.context.companyId,
      },
    })

    commit(t.LOAD_PRODUCTS, { kind: 'registered_agent_product', products: results.data.result })
  },

  async loadSimpleProducts({ commit, getters }) {
    const results = await http.get(`client/products/simple_products`, {
      params: {
        company_id: getters.context.companyId,
      },
    })

    commit(t.LOAD_PRODUCTS, { kind: 'simple_product', products: results.data.result })
  },

  async loadProductBundles({ commit, getters }) {
    const results = await http.get(`client/products/product_bundles`, {
      params: {
        company_id: getters.context.companyId,
      },
    })

    commit(t.LOAD_PRODUCTS, { kind: 'product_bundle', products: results.data.result })
  },

  async loadSupplyAndConsultProducts({ commit, getters }) {
    const results = await http.get(`client/products/supply_and_consult_products`, {
      params: {
        company_id: getters.context.companyId,
      },
    })

    commit(t.LOAD_PRODUCTS, { kind: 'supply_and_consult_product', products: results.data.result })
  },

  async loadProducts({ commit, getters }, companyId) {
    if (!companyId && !getters.context.companyId) throw new Error('Failed to load products—company ID required.')
    if (getters.productsLoading) return
    commit(t.LOAD_PRODUCTS_START)

    for (let [kind, pathPart] of Object.entries(PRODUCT_PATH_PARTS_BY_KIND)) {
      const path = `client/products/${pathPart}`
      const params = { company_id: getters.context.companyId || companyId }

      if (kind === 'filing_product') {
        params.entity_type_id = getters.entityTypeId
      }

      if (kind === 'subscription_bundle') {
        // Commented out since we don't currently offer subscription bundles and this call is very slow
        // TODO : Uncomment when we actually need to load these
        commit(t.LOAD_PRODUCTS, { kind, products: [] })
        continue

        // if (getters.domesticJurisdiction) {
        //   params.jurisdiction_id = getters.domesticJurisdiction.id
        // } else if (rootGetters['companies/checkoutDomesticJurisdiction']?.id) {
        //   params.jurisdiction_id = rootGetters['companies/checkoutDomesticJurisdiction']?.id
        // } else {
        //   commit(t.LOAD_PRODUCTS, { kind, products: [] })
        // }
      }

      const results = await http.get(path, { params })

      commit(t.LOAD_PRODUCTS, { kind, products: results.data.result })
    }

    commit(t.LOAD_PRODUCTS_FINISHED)
  },

  async setupContext({ commit, getters, dispatch }, context) {
    const loadCart = !!context.companyId && (context.companyId !== getters.context.companyId)

    commit(t.SET_HIRE_US_CONTEXT, context)

    if (loadCart) {
      await dispatch('companies/loadIfNotAvailableById', { id: context.companyId }, { root: true })
      await dispatch('loadCartItems')
      commit(t.LOAD_PRODUCTS_STATUS, false)
    }
  },

  toggleOptionalItem({ commit, dispatch }, { name }) {
    commit(t.TOGGLE_OPTIONAL_ITEM, name)
    dispatch('resolveOptionalItems', {})
  },

  toggleHireUsOptionalItem({ commit, dispatch, getters }, { name, optionalParentFilingProduct = '' }) {
    commit(t.TOGGLE_HIRE_US_OPTIONAL_ITEM, name)
    dispatch('resolveOptionalItems', { optionalAddOn: true, optionalParentFilingProduct, optionalItemSetup: getters.hireUsOptionalItemSetup })
  },

  async resolveOptionalItems({ getters, dispatch }, { optionalAddOn = false, optionalParentFilingProduct = '', optionalItemSetup = getters.optionalItemSetup }) {
    if (getters.ghostMode) return

    for (let { enabled, kind, predicate, parentProductKind } of Object.values(optionalItemSetup)) {

      const optionalProduct = getters.findProductBy(kind, predicate)

      if (!optionalProduct) continue

      if (enabled) {
        const existingOptionalProducts = getters.cartItems.filter(item => item.product_id === optionalProduct.id)

        const newItems = []

        let parentItems = getters.cartItemsByKind(parentProductKind)
        if (optionalParentFilingProduct) parentItems = parentItems.concat(getters.filingProductCategoryCartItems(optionalParentFilingProduct))
        for (let parentItem of parentItems) {
          if (parentItem.parent_item_id) {
            continue
          }

          const existingItem = existingOptionalProducts.find(item => item.parent_item_id === parentItem.id)

          if (!existingItem) {
            const fields = {
              parent_item_id: parentItem.id,
              product_id: optionalProduct.id,
              product_type: optionalProduct.kind,
              data: {},
              optional_add_on: optionalAddOn,
            }

            const parentProduct = getters.findProduct(parentItem.product_id)
            const existingOrphanItem = existingOptionalProducts.find(item => item.product_id === optionalProduct.id && !item.parent_item_id)

            if (optionalProduct.category === 'compliance' && parentProduct?.jurisdiction === 'Federal') {
              continue
            } else if (optionalProduct.category === 'law-on-call' && (parentProduct?.jurisdiction !== 'Utah' || existingOrphanItem)) {
              continue
            } else if (optionalProduct.category === 'monitoring' && parentProduct.category !== 'Trademark') {
              continue
            } else if (optionalProduct.category === 'compliance' || optionalProduct.category === 'law-on-call') {
              fields.data.state = parentProduct.jurisdiction
            }

            if (parentProduct.kind === 'filing_product' && optionalProduct.category === 'compliance') {
              const selectedFilingMethod = parentProduct.filing_methods.find(fm => fm.id === parentItem.filing_method_id)
              fields.data.state = selectedFilingMethod.jurisdiction

              if (getters.renewalServiceWithJurisdictionInCart(selectedFilingMethod.jurisdiction)) {
                continue
              }
            }

            if (parentProduct.kind === 'registered_agent_product' && optionalProduct.category === 'compliance')  {
              if (getters.renewalServiceWithJurisdictionInCart(parentProduct.jurisdiction)) {
                continue
              }
            }

            if (optionalProduct.filing_name === 'change registered agent') {
              const filingMethods = optionalProduct.filing_methods.filter(fm => fm.jurisdiction === parentProduct.jurisdiction)
              const filingMethod = sortBy(filingMethods, 'cost')[0]

              if (!filingMethod) {
                continue
              }

              fields.filing_method_id = filingMethod.id
            }

            newItems.push(fields)
          }
        }

        if (newItems.length > 0) {
          const path = `client/companies/${getters.context.companyId}/shopping_basket_items/batch_create`
          await http.post(path, { items: newItems })
        }
      } else {
        const ids = getters.cartItems
          .filter(item => item.product_id === optionalProduct.id && !!item.parent_item_id)
          .map(item => item.id)

        if (ids.length) {
          await http.post(`client/companies/${getters.context.companyId}/shopping_basket_items/batch_delete`, { ids })
        }
      }
    }

    dispatch('loadCartItems')
  },

  setAgreementAccepted({ commit }, newValue) {
    commit(t.SET_AGREEMENT_ACCEPTED, newValue)
  },

  setProcessingCheckout({ commit }, newValue) {
    commit(t.SET_PROCESSING_CHECKOUT, newValue)
  },

  setCheckoutDisabled({ commit }, newValue) {
    commit(t.SET_CHECKOUT_DISABLED, newValue)
  },

  async checkout({ commit, dispatch, getters, _rootState, rootGetters }, vm) {
    dispatch('setProcessingCheckout', true)
    if (!getters.selectedPaymentMethod) {
      vm.$bvToast.toast('Please select a payment method.', {
        title: 'Error',
        variant: 'danger',
        solid: true,
      })
      return false
    } else if (rootGetters['paymentMethods/isSelectedExpired']) {
      vm.$bvToast.toast('Your card is expired. Please select another payment method.', {
        title: 'Error',
        variant: 'danger',
        solid: true,
      })
      return false
    }

    if (getters.cartEmpty) {
      vm.$bvToast.toast('Please select an item to complete checkout',{
        title: 'Error',
        variant: 'danger',
        solid: true,
      })
      return false
    }

    try {
      const possibleOrigins = ['hire-us', 'stageline', 'vehicle-registrations']
      const urlParts        = window.location.href.split('/')
      let originOfSale      = ('origin' in vm) && possibleOrigins.includes(vm.origin) ?
        vm.origin :
        urlParts.find(entry => possibleOrigins.includes(entry))

      // Mark invoices created by admins.
      if (sessionStorage.getItem('admin-logged-in')) {
        originOfSale += '-admin-created'
      }

      const checkoutResponse = await http.get(
        `client/companies/${getters.context.companyId}/shopping_basket_items/checkout`, {
          params: {
            token: getters.selectedPaymentMethod.id,
            origin: originOfSale,
          },
        }
      )

      //TODO: maybe move this out of here, as it really should have separation. Move this to regular Cart?
      const invoice  = checkoutResponse?.data?.result?.invoice
      const order    = checkoutResponse?.data?.result?.order
      const services = checkoutResponse?.data?.result?.services

      // Add these newly created order items and services to our companies active order items
      // and services
      if (order) {
        dispatch('companies/addActiveOrderItems', order.order_items, { root: true })
        const oraCount = (order?.order_items?.filter((o) => o.needs_attention)?.length || 0)
        if (oraCount > 0) {
          dispatch('dashpanel/incrementOraCount', oraCount, { root: true })
        }
      }
      if (services) {
        dispatch('companies/addActiveServices', services, { root: true })
        for (const service of services) {
          if (service.type === "virtual-phone") {
            dispatch('virtualPhones/loadVirtualPhones', null, { root: true })
            break
          }
        }
      }

      if (!rootGetters['stageline/isStagelineRoute'] && !vm.verifyOrderCheckoutButtonOverrides && !originOfSale.includes('vehicle-registrations')) {
        if (vm?.hireUsInteraction) {
          await completeInteraction({
            id: vm.hireUsInteraction.id,
            interaction: { type: 'button', action: 'redirect', name: 'complete-checkout' },
            completed: true,
            invoice_id : invoice.id,
          })
        }

        await router.push({ name: 'order-confirmation', params: { invoiceId: invoice.id } })
        // TODO: remove at a later time, keeping just in case it is being used
        // if (order && order.order_items.filter(item => item.product.kind === 'filing_product' && item.status === 'awaiting-client-input' && _.isEmpty(item.data)).length) {
        //   router.push({ name: 'post-payment', params: { companyId: getters.context.companyId, invoiceId: invoice.id } })
        // } else {
        //   router.push({ name: 'review', params: { invoiceId: invoice.id } })
        // }
      }

      commit(t.RESET_STATE_AFTER_CHECKOUT)
      dispatch('postCheckout', { invoice, order })
      return checkoutResponse.data.result
    } catch (error ) {
      dispatch('setProcessingCheckout', false)
      vm.$bvToast.toast(error.response.data.error.message,{
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 10000,
      })
      return false
    }
  },

  resetState({ commit }) {
    commit(t.RESET_STATE)
  },

  updateItemData({ commit }, itemData) {
    Object.entries(itemData).forEach(([id, data]) => {
      commit(t.UPDATE_CART_ITEM_DATA, { id, data })
    })
  },
  async updateCartItemData ({ getters, commit }, { id, data }) {
    if (getters.ghostMode) return

    const response = await http.patch(`client/companies/${getters.context.companyId}/shopping_basket_items/${id}`, {
      shopping_basket_item: {
        data: data,
      },
    })
    if (response.data.success) commit(t.UPDATE_CART_ITEM_DATA, { id, data })
  },

  setCurrentProduct({ commit }, product) {
    commit(t.SET_CURRENT_PRODUCT, product || null)
  },
  // Post checkout doesnt interfere with the actual checkout method since it doesnt await
  async postCheckout({ _commit }, { invoice, order }) {
    const orderItems = order?.order_items || []
    const promoCodeId = invoice.invoice_line_items.find(ili => ili.product?.kind === 'promo_codes')?.product?.id

    for(const orderItem of orderItems) {
      const productCategory = orderItem.product?.category
      if (['operating agreement', 'corporate bylaws'].includes(productCategory) && invoice?.company_id) {
        await http.post(`client/companies/${invoice.company_id}/upload_filing_agency_resource`, {
          category: productCategory,
          sub_category: 'premium',
          fill_fields: true,
          replace_document: true,
        })
      }
    }

    if (promoCodeId) {
      createOrFindClientInteractionLog({
        category: 'promo-codes',
        subCategory: 'promo-codes',
        objectId: promoCodeId,
        objectTable: 'PromoCode',
        companyId: invoice.company_id,
        interaction: {
          action: 'checkout',
        },
        completeLog: true,
      })
    }
  },
}

const MUTATIONS = {
  [t.CART_ITEMS_LOADED](state, cartItems) {
    state.cartItems = cartItems
  },
  [t.LOAD_PRODUCTS_START](state) {
    state.productsLoaded  = false
    state.productsLoading = true
  },

  [t.LOAD_PRODUCTS](state, { kind, products }) {
    Vue.set(state.products, kind, sortBy(products, 'name'))
  },

  [t.LOAD_PRODUCTS_FINISHED](state) {
    state.productsLoading = false
    state.productsLoaded  = true
  },

  [t.LOAD_PRODUCTS_STATUS](state, status) {
    state.productsLoaded = status
  },

  [t.SET_HIRE_US_CONTEXT](state, context) {
    Object.assign(state.context, context)
  },

  [t.SET_AGREEMENT_ACCEPTED](state, newVal) {
    state.agreementAccepted = newVal
  },

  [t.SET_PROCESSING_CHECKOUT](state, newVal) {
    state.processingCheckout = newVal
  },

  [t.SET_CHECKOUT_DISABLED](state, newVal) {
    state.checkoutDisabled = newVal
  },

  [t.UPDATE_CART_ITEM_DATA](state, { id, data }) {
    Vue.set(state.cartItems.find(x => x.id === id), 'data', data)
  },

  [t.TOGGLE_OPTIONAL_ITEM](state, name) {
    state.optionalItemSetup[name].enabled = !state.optionalItemSetup[name].enabled
  },

  [t.TOGGLE_HIRE_US_OPTIONAL_ITEM](state, name) {
    state.hireUsOptionalItemSetup[name].enabled = !state.hireUsOptionalItemSetup[name].enabled
  },

  [t.RESET_STATE_AFTER_CHECKOUT] (state) {
    Object.assign(state, _.omit(getDefaultState(), 'context', 'products', 'productLoaded'))
  },

  [t.RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },

  [t.SET_CURRENT_PRODUCT](state, currentProduct) {
    state.currentProduct = currentProduct
  },

  [t.RESET_CURRENT_BUNDLE_ITEMS](state) {
    state.currentBundleItems = {}
  },

  [t.SET_CURRENT_BUNDLE_ITEMS](state, { productId, bundleItem }) {
    Vue.set(state.currentBundleItems, productId, bundleItem)
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
