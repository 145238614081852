import * as t from '../../../mutations'
import http from '@/http'
import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import * as Cookies from 'js-cookie'
import _ from 'lodash'
import router from '@/routes'
import { shouldShowOrHideFiling } from './stagelineV2StoreHelper'

// someday set these in a JSONB column on the period so it's dynamic
import {
  START_A_BUSINESS_PERIOD_SUPPORTED_ENTITY_TYPES,
  CORPORATE_TRANSPARENCY_ACT_PHASE_SUPPORTED_ENTITY_TYPES,
} from './stagelineSupportedEntityTypes'

const initialState = () => ({
  accountCompanies: [],
  companyStageData: null,
  ctaSupportedEntityTypes: CORPORATE_TRANSPARENCY_ACT_PHASE_SUPPORTED_ENTITY_TYPES,
  currentSlide: null,
  currentPeriodName: null,
  decisionTiles: [],
  finalizeLegalDocResourceTypes: ['operating agreement', 'corporate bylaws'],
  ghostMode: false,
  jurisdiction: null,
  lazyLoadedDocType: ['operating agreement', 'corporate bylaws', 'business plan'],
  objectsRequiringAttention: [],
  periods: [],
  periodsLoaded: false,
  processFilingObjects: [],
  productSlideProducts: [],
  productPurchased: false,
  progress: null,
  showEndOfStageSlide: false,
  slideLoaded: false,
  stagelineLoaded: false,
  startingField: '',
  slideAgencyResource: null,
  startSupportedEntityTypes: START_A_BUSINESS_PERIOD_SUPPORTED_ENTITY_TYPES,
})

const STATE = initialState()

const GETTERS = {
  accountCompanies:             state => state.accountCompanies,
  decisionTiles:                state => state.decisionTiles,
  finalizeLegalDocResourceTypes: state => state.finalizeLegalDocResourceTypes,
  jurisdiction:                 state => state.jurisdiction,
  lazyLoadedDocType:            state => state.lazyLoadedDocType,
  showEndOfStageSlide:          state => state.showEndOfStageSlide,
  slideLoaded:                  state => state.slideLoaded,
  slideAgencyResource:          state => state.slideAgencyResource,
  stagelineLoaded:              state => state.stagelineLoaded,
  startingField:                state => state.startingField,
  objectsRequiringAttention:    state => state.processFilingObjects.filter(o => o.status === 'awaiting-client-input'),
  periodsLoaded:                state => state.periodsLoaded,
  periods:                      state => state.periods,
  progress:                     state => state.progress,
  processFilingObjects:         state => state.processFilingObjects,

  currentField:                 (_state, getters) => getters.progress?.current_field,
  currentObjectId:              (_state, getters) => getters.progress?.current_object_id,
  filteredPeriods:               (_state, getters) => {
    let currentStagelineRecordIds = []
    if (getters.currentSlide) {
      currentStagelineRecordIds = [
        getters.currentSlide.step.stage.period.id,
        getters.currentSlide.step.stage.id,
        getters.currentSlide.step.id,
        getters.currentSlide.id,
      ]
    }

    let filteredPeriods = cloneDeep(getters.periods)

    for (const period of filteredPeriods) {
      // Only filter deep-loaded periods
      if (getters.periodDeepLoaded(period.id)) {
        period.stages = period.stages.map(stage => {
          stage.steps = stage.steps.map(step => {
            step.slides = step?.slides?.filter(slide => {
              return slide && getters.showStagelineElement(slide, currentStagelineRecordIds, stage)
            })
            return step.slides?.length > 0 ? step : null
          }).filter(step => step !== null && getters.showStagelineElement(step, currentStagelineRecordIds, stage))
          return stage.steps.length > 0 ? stage : null
        }).filter(stage => stage !== null && getters.showStagelineElement(stage, currentStagelineRecordIds, stage))
      }
    }

    return filteredPeriods
  },
  filteredPeriodSlideList:       (_state, getters) => (periodName, unfiltered = false) => {
    // Creates a flat list and gives each slide a navigable tree allowing access of ancestors via dot notation
    // Unfiltered parameter for fetching slides outside of what is normally filtered for a company
    // Ex: company details has been filled out, so company structure slide is automatically removed
    // Allow client to return to slide to change details
    if (!getters.periodDeepLoaded(periodName)) return []

    const periods = unfiltered ? getters.periods : getters.filteredPeriods
    const period = periods.find(p => p.name === periodName)
    if (!period) return []

    return period.stages.flatMap(stage =>
      stage.steps.flatMap(step =>
        step.slides.map(slide => ({
          ...slide,
          step: {
            id: step.id,
            name: step.name,
            stage: {
              id: stage.id,
              name: stage.name,
              JurisdictionFilter: stage.show_for_jurisdictions,
              period: {
                id: period.id,
                name: period.name,
              },
            },
          },
        }))
      )
    )
  },
  filteredCurrentStageSlideList: (_state, getters) => {
    return getters.filteredPeriodSlideList(getters.currentPeriodName)?.filter(slide => slide.step.stage.id === getters.currentStage?.id) || []
  },
  filteredStageSlideListByStage: (_state, getters) => (stage) => {
    return getters.filteredPeriodSlideList(getters.currentPeriodName)?.filter(slide => slide.step.stage.id === stage.id) || []
  },
  periodDeepLoaded:             (_state, getters) => periodIdOrName => !!getters.periods.find(p => [p.id, p.name].includes(periodIdOrName))?.stages,
  stagelineConfig:               (_, getters) => {
    /* Merge configs from the entire context, allowing more granular configs to override
    higher-level configs (e.g. a Period may set hide_stageline_sidebar to false, but a Step can
    override that and set it to true just for itself).

    Schema e.g.:
    {
     "back_button": { "hide": true },
     "continue_button": { "hide": false },
     "hide_navigation": true,
     "hide_sidebar": false,
    }*/
    return {
      ...getters.currentPeriod?.config,
      ...getters.currentStage?.config,
      ...getters.currentStep?.config,
      ...getters.currentSlide?.config,
    }
  },

  /* ======== START PERIOD ====================================================================== */
  startSupportedEntityTypes:    state => state.startSupportedEntityTypes,
  startEntityTypeSupported:     (_state, getters) => getters.startSupportedEntityTypes.includes(getters.company.entity_type),

  /* ======== CTA PERIOD ======================================================================== */
  ctaSupportedEntityTypes:      state => state.ctaSupportedEntityTypes,
  ctaEntityTypeSupported:       (_state, getters) => getters.ctaSupportedEntityTypes.includes(getters.company.entity_type),
  isCtaPeriod:                  (_state, getters) => getters.currentPeriodName === 'corporate_transparency_act',

  /* ======== Current Slide ===================================================================== */
  currentSlide:              state => state.currentSlide,
  currentSlideLayoutType:    (_state, getters) => getters.currentSlide?.layout_type || 'resting',
  currentSlideLayoutSubType: (_state, getters) => getters.currentSlide?.layout_sub_type || '',
  currentSlideIndex:         (_state, getters) => {
    return getters.currentStep?.slides.findIndex(slide => slide.id === getters.currentSlide?.id)
  },
  currentStageSlideList:     (_state, getters) => {
    return getters.currentPeriodSlides?.filter(slide => slide.step.stage.id === getters.currentStage.id) || []
  },
  currentSlideIsFirstOfCurrentStage: (_state, getters) => {
    return getters.currentPeriodSlides.find(
      slide => slide.step.stage.id === getters.currentStage.id
    ).id === getters.currentSlide.id
  },
  currentSlideIsLastOfCurrentStage:  (_state, getters) => getters.slideIsLast && getters.stepIsLast,
  currentSlideIsLastOfCurrentPeriod: (_state, getters) => {
    const index = getters.filteredPeriodSlideList(getters.currentPeriodName)
      .findIndex(slide => slide.id === getters.currentSlide.id)
    return index === getters.filteredPeriodSlideList(getters.currentPeriodName).length - 1
  },
  currentSlideProductSelectionRequired: (_state, getters) => {
    return getters.currentSlideLayoutType === 'filingOptions' &&
      !(getters.productSlideProducts?.some(slideProduct => getters.slideProductSelected(slideProduct)) ||
        (getters.currentSlideLayoutSubType === 'form a company' && !!getters.formationFiling))
      || false
  },
  currentSlidePeriodIndex:              (_state, getters) =>{
    return getters.currentPeriodSlides.findIndex(slide => slide.id === getters.currentSlide?.id)
  },

  /* ======== Current Step ====================================================================== */
  currentStep:      (_state, getters) => {
    return getters.currentStage?.steps?.find(step => step.id === getters.currentSlide?.step.id)
  },
  currentStepIndex: (_state, getters) => {
    return getters.currentStage?.steps.findIndex(step => step.id === getters.currentStep?.id)
  },

  /* ======== Current Stage ===================================================================== */
  companyStageData:  state => state.companyStageData,
  currentStage:      (_state, getters) => {
    return getters.currentPeriod?.stages?.find(stage => stage.id === getters.currentSlide?.step.stage.id)
  },
  currentStageIndex: (_state, getters) => {
    return getters.currentPeriodStages ?
      getters.currentPeriodStages.findIndex(stage => stage.id === getters.currentStage?.id) :
      null
  },
  currentStageSlidesComplete: (_state, getters) => {
    return getters.filteredCurrentStageSlideList.every(slide => !!slide.completed_at)
  },
  currentStageHasUnsupportedDocumentSlide:    (_state, getters) => {
    return getters.currentStageDocumentSlide ?
      !('agency_resource' in getters.currentStageDocumentSlide) :
      true
  },
  currentStageDocumentSubTypeProductCartItem: (_state, getters, _rootState, rootGetters) => {
    return getters.currentStageDocumentSlide?.layout_sub_type ?
      rootGetters['checkout/cartItemByCategoryAndJurisdiction'](getters.currentStageDocumentSlide?.layout_sub_type, getters.jurisdiction) :
      {}
  },
  currentStageDocumentSubTypeProductBundleCartItem: (_state, getters, _rootState, rootGetters) => {
    return getters.currentStageDocumentSlide?.layout_sub_type ?
      rootGetters['checkout/bundleInCartWithFilingProduct'](getters.currentStageDocumentSlide?.layout_sub_type) :
      {}
  },
  currentStageDocumentSlide: (_state, getters) => {
    return getters.filteredCurrentStageSlideList.find(slide => slide.layout_type === 'document')
  },
  stageProductSelectedType:  (_state, getters) => {
    return getters.currentSlide ? getters.companyStageData?.selected_product_type : null
  },

  /* ======== Current Period ==================================================================== */
  currentPeriod:       (_state, getters)=> {
    return getters.filteredPeriods.find(period => period.name === getters.currentPeriodName)
  },
  currentPeriodName:   state => state.currentPeriodName,
  currentPeriodIndex:  (_state, getters) => {
    return getters.filteredPeriods.findIndex(period => period.name === getters.currentPeriodName)
  },
  currentPeriodSlides: (_state, getters) => {
    return getters.filteredPeriodSlideList(getters.currentPeriodName)
  },
  currentPeriodStages: (_state, getters) => {
    return getters.currentPeriod ? getters.currentPeriod?.stages : []
  },

  /* ======== PDF VIEWER/Document Slide  ======================================================== */
  captureMonsterCompanyNeedsFormDataProcessed: (_state, getters) =>  {
    // TODO - Fix to only work for formation stage or all stages
    // Company completed PDF in capture-monster and form data has not yet been processed
    return getters.companyConfig.formation_source === 'capture-monster' &&
      getters.companyConfig.frontend_pdf_completed &&
      !getters.companyConfig.form_data_processed
  },
  captureMonsterSelectedStageProductType: (_state, getters) => slide => {
    const companyConfig = getters.company.config
    return slide?.layout_sub_type === companyConfig?.capture_monster_pdf_category &&
    companyConfig?.capture_monster_pdf_sub_category ?
      companyConfig?.capture_monster_pdf_sub_category :
      ''
  },
  captureMonsterSelectedProductTypeNotSet: (_state, getters) => (slide, stage) => {
    const stageSelectedProductType = getters.stageProductSelectedType || stage?.data?.selected_product_type
    return !stageSelectedProductType && getters.captureMonsterSelectedStageProductType(slide)
  },
  pdfViewerAgencyResource:   (_state, getters) => {
    // TODO [STAG-2609]
    return getters.getSlideByIdOrName('form_the_company__fill_out_form__pdf_viewer')?.agency_resource
  },
  pdfViewerAgencyResourceId: (_state, getters) => {
    return getters.pdfViewerAgencyResource?.id
  },
  processedFormData:         (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['stagelineSchemaForm/processedFormData']
  },

  /* ======== Find Stageline Record  ============================================================ */
  firstIncompleteSlideId:   (_state, getters) => ({ type, typeId }) => {
    if (!['step', 'stage', 'period'].includes(type)) return
    const filteredSlides = getters.currentPeriodSlides.filter(slide =>
      [slide.step.stage.period.id, slide.step.stage.id, slide.step.id].includes(typeId)
    )

    // Find the last completed slide and take priority over the slides proceeding it. That way we
    // can maintain state without a refresh. Example issue is the select filing option for
    // form a company, if you hit this method again after going to filing status, you will not
    // go to the correct slide without a refresh
    const lastCompletedSlideIndex = filteredSlides.findLastIndex(s => s.completed_at)
    const sortedFilteredSlides = lastCompletedSlideIndex === -1
      ? filteredSlides
      : [...filteredSlides.slice(lastCompletedSlideIndex), ...filteredSlides.slice(0, lastCompletedSlideIndex)]

    return sortedFilteredSlides.find((slide, i) => slide.completed_at === null || i === filteredSlides.length - 1)?.id
  },
  getSlideByIdOrName:      (_state, getters) => (slideIdOrName) => {
    return getters.currentPeriodSlides.find(slide => [slide.id, slide.name].includes(slideIdOrName))
  },
  getStageByIdOrName:      (_state, getters) => (stageIdOrName) => {
    return getters.currentPeriodStages.find(stage => [stage.id, stage.name].includes(stageIdOrName))
  },
  getSlideByLayoutType:    (_state, getters) => (layoutType) => {
    return getters.currentPeriodSlides.find(slide => [slide.layout_type].includes(layoutType))
  },
  getStepByIndex:          (_state, getters) => (index) => {
    return getters.currentStage?.steps[index]
  },
  slidePeriodIndex:        (_state, getters) => (slide) => {
    return getters.currentPeriodSlides.findIndex(s => s.id === slide?.id)
  },
  slideIsLast:             (_state, getters) => {
    return getters.currentSlideIndex === getters.currentStep?.slides.length - 1
  },
  stagelineHasBeenVisited: (_state, getters) => {
    return getters.company?.stageline?.last_visited_slide ||
      getters.currentPeriodSlides.some((slide) => !!slide.last_visited)
  },
  stageIsLast: (_state, getters) => {
    return getters.currentStageIndex === getters.currentPeriod?.stages.length - 1
  },
  stepCompletedSlideIds:  _state => (step) => {
    return step.slides.filter(slide => !!slide.completed_at)
  },
  stepIsLast:  (_state, getters) => {
    return getters.currentStepIndex === getters.currentStage?.steps.length - 1
  },
  stepIsFirst: (_state, getters) => getters.currentStepIndex === 0,

  /* ======== Mapped from Companies store ======================================================= */
  company:         (_state, _getters, _rootState, rootGetters) => rootGetters['companies/currentCompany'],
  companyConfig:    (_state, getters) => getters.company.config,
  formationFiling: (_state, _getters, _rootState, rootGetters) => rootGetters['companies/formationFiling'],
  einFiling:       (_state, _getters, _rootState, rootGetters) => rootGetters['companies/einFiling'],
  formedElsewhere: (_state, _getters, _rootState, rootGetters) => rootGetters['companies/formedElsewhere'],

  /* ======== stages, steps and routing ========================================================= */
  companyStagelineRoute:       (_state, getters) => getters.company?.stageline?.route,
  isStagelineRoute:            (_state, getters) => {
    return getters.route.name && getters.route.name.includes('stageline')
  },
  isStagelineNonCheckoutRoute: (_state, getters) => {
    return getters.route.name && getters.route.name.includes('stageline') &&
      !getters.route.name.includes('checkout')
  },
  route:                       (_state, _getters, rootState) => rootState.appRouter,

  /* ======== cart items [Use for stage and step filtering, not checkout logic.] ================= */
  boiFilingInCart:          (_state, _getters, _rootState, rootGetters) => rootGetters['checkout/boiFilingInCart'],
  cartItemExistsByCategory: (_state, getters, _rootState, rootGetters) => (type) => {
    return rootGetters['checkout/cartItemExistsByCategoryAndJurisdiction'](type, getters.jurisdiction)
  },
  cartEmpty:                (_state, _getters, _rootState, rootGetters) => rootGetters['checkout/cartEmpty'],
  raServiceInCart:          (_state, getters, _rootState, rootGetters) => {
    return !!(getters.cartItemExistsByCategory('registered-agent') ||
      rootGetters['checkout/bundleCartItemsByCategoryAndJurisdiction']('registered-agent', getters.jurisdiction).length)
  },

  /* ======== active filings and services ======================================================== */
  activeBoiFiling:                 (_state, _getters, _rootState, rootGetters) => rootGetters['companies/activeBoiFiling'],
  completedFormACompanyFiling:     (_state, _getters, _rootState, rootGetters) => rootGetters['companies/completedFormACompanyFiling'],
  formACompanyFiling:              (_state, _getters, _rootState, rootGetters) => rootGetters['companies/formACompanyFiling'],
  simpleProductOperatingAgreement: (_state, _getters, _rootState, rootGetters) => rootGetters['companies/simpleProductOperatingAgreement'],
  hasActiveProduct:    (_state, _getters, _rootState, rootGetters) => (productNames) => {
    if (productNames.typeof === 'string') productNames = [productNames]
    return productNames.some(name => rootGetters['companies/activeOrderItemsByProductName'](name))
  },
  isFormationFiling:   (_state, _getters) => (filingName) => {
    return ['vehicle form a company', 'form a company', 'free form a company'].includes(filingName)
  },
  isFinalizeLegalDocs: (state, getters) => {
    return state.finalizeLegalDocResourceTypes.includes(getters.currentSlideLayoutSubType)
  },
  isLazyLoadedDocType: (state, getters) => {
    return state.lazyLoadedDocType.includes(getters.currentSlideLayoutSubType)
  },
  jurisdictionFilings: (_state, getters, _rootState, rootGetters) => {
    const jurisdictions = [getters.jurisdiction?.state_province_region, 'Federal']
    return rootGetters['companies/activeOrderItemsByJurisdictions'](jurisdictions)
  },
  purchasedLegalDocs:  (_state, getters) =>  {
    return getters.hasActiveProduct(['Premium Operating Agreement', 'Premium Corporate Bylaws']) ||
      getters.simpleProductOperatingAgreement ||
      getters.companyConfig?.capture_monster_pdf_sub_category === 'premium'
  },

  /* ======== Filter Functions =================================================================== */
  /*
  Allows us to set filters for Stageline records in the database using the "filters" column,
  which checks against values in the companies.config column.

  As long as we're filtering based on a key/value pair in the companies.config column, we don't
  have to write a new method on the frontend for every single new filter. This will be helpful
  for "options slides", which update a value in the companies.config column and immediately
  update locally. We can then apply filters based on that value for filtering subsequent slides.

  Filters on Stageline records can be namespaced under 'company', 'account', etc.
*/
  showStagelineElement: (_state, getters) => (el, currentStagelineRecordIds, stage) => {
    const filterRequiresStage = [
      'filterSlideBySelectedProductType',
      'filterSlideByCartEmptyOrNoStageProducts',
    ]
    const columnFilters = [
      'filterShowForJurisdiction',
    ]
    const periodFilters = []
    const stageFilters = []
    const stepFilters = [
      'filterStepByMissingDocumentStatus',
      'filterStepBySlideProducts',
      'filterStepByCompanyInitialFilingDocument',
      'filterStepByCompanyInitialResolutionDocument',
      'filterStepByHideForBundles',
    ]
    const slideFilters = [
      'filterSlideByCartEmptyOrNoStageProducts',
      'filterSlideBySlideCheckoutType',
      'filterSlideBySlideProducts',
      'filterShowForFiling',
      'filterHideForFiling',
      'filterHideForManagementType',
      'filterSlideBySelectedProductType',
      'filterServiceInfoSlides',
      'filterServiceConfigurationSlides',
      'filterSlideByMissingEinDocument',
    ]

    let categoricalFilters = slideFilters
    if (el.stages) categoricalFilters = periodFilters
    if (el.steps) categoricalFilters = stageFilters
    if (el.slides) categoricalFilters = stepFilters

    return [
      ...columnFilters,
      ...categoricalFilters,
      'filterElementByConfigFilters',
    ].every(filter => {
      return currentStagelineRecordIds.includes(el.id) ||
        (
          filterRequiresStage.includes(filter) ?
            getters[filter](el, stage) :
            getters[filter](el)
        )
    })
  },

  /* ======== General Filters =================================================================== */
  filterElementByConfigFilters: (_state, getters) => el => {
    const companyFilters = el?.config_filters?.company
    if (!companyFilters?.length) return true

    return companyFilters.every(filter => {
      const path = filter[0]
      const values = filter[1]
      return values.includes(_.get(getters.company?.config, path))
    })
  },
  filterShowForJurisdiction:   (_state, getters) => el => {
    // TODO [STAG-2524] - Move jurisdiction filter to backend
    return el.show_for_jurisdictions?.length ?
      el.show_for_jurisdictions.includes(getters.jurisdiction?.abbreviation) :
      true
  },

  /* ======== Step Filters ====================================================================== */
  filterStepByCompanyInitialResolutionDocument: (_state, getters) => step => {
    // TODO [STAG-2521] - remove after creating show_for_document_type_filter
    const initialResolutionSteps = [
      'llc_initial_resolutions',
      'corp_initial_resolutions',
    ]

    return initialResolutionSteps.includes(step.name) ?
      !!getters.company.initial_resolution_document_id :
      true
  },
  filterStepByCompanyInitialFilingDocument:     (_state, getters) => step => {
    // TODO [STAG-2481] - extract to stagelineForm store
    // TODO [STAG-2610]
    const hasValidFormationDocument = getters.company.initial_filing_document_id &&
      !getters.company.formation_document_id

    return step.name === 'form_the_company__review_formation_document' ?
      !hasValidFormationDocument :
      true
  },
  filterStepByHideForBundles:        (_state, getters, _rootState, rootGetters) => step => {
    if (!step.hide_for_bundles?.length) return true

    const activeProductBundles = rootGetters['companies/activeProductBundles']
    const shouldHide = step.hide_for_bundles.some(bundleName =>
      activeProductBundles.some(bundle => bundle?.product_categorization?.subcategory === bundleName)
    )

    return !shouldHide
  },
  filterStepByMissingDocumentStatus: (_state, getters) => step => {
    return getters.stepHasUnsupportedDocumentSlide(step)
  },
  filterStepBySlideProducts:         (_state) => step => {
    return (!(step.slides.some(slide =>
      slide.layout_type === 'product' &&
      !slide.products.length &&
      step.slides.length <= 1
    )))
  },

  /* ======== Slide Filters ===================================================================== */
  filterSlideByMissingEinDocument: (_state, getters) => (slide) => {
    // TODO [STAG-2521] - remove after creating show_for_document_type_filter
    const filterSlidesIfMissingEinDoc = [
      'ein__hired_us__document',
      'ein__hired_us__view_ein',
    ]

    return filterSlidesIfMissingEinDoc.includes(slide.name) ?
      !!getters.company.ein_letter_document_id :
      true
  },
  filterSlideByCartEmptyOrNoStageProducts: (_state, getters) => (slide, stage) => {
    if (slide.layout_type !== 'checkout') return true
    if (getters.cartEmpty) return false


    // Check if there are any product slides in the stage
    return stage?.steps?.some(step =>
      step.slides.some(slide =>
        ['product', 'filingOptions', 'productOptions'].includes(slide.layout_type)
        && slide.products?.length
      )
    )
  },
  filterShowForFiling: (_state, getters, _rootState) => slide => {
    const filter = slide.show_for_filings
    if (!filter?.length) return true
    const shouldShowFiling = !!shouldShowOrHideFiling(filter, getters, getters.jurisdiction)


    // Check for companies who have selected product type set and no filings.
    // This way we can check show slides if they have the filing or selected product type.
    if (
      !shouldShowFiling &&
      slide.show_for_selected_product_types?.length &&
      getters.stageProductSelectedType
    ) {
      return getters.filterSlideBySelectedProductType(slide)
    }

    return shouldShowFiling
  },
  filterHideForFiling: (_state, getters, _rootState) => slide => {
    const filter = slide.hide_for_filings
    if (!filter?.length) return true
    return !shouldShowOrHideFiling(filter, getters, getters.jurisdiction)
  },
  filterServiceInfoSlides: (_state, getters,  _rootState, rootGetters) => slide => {
    return slide?.layout_type === 'serviceInfo' ?
      rootGetters['companies/activeServicesByType'](slide.layout_sub_type).length :
      true
  },
  filterServiceConfigurationSlides: (_state, _getters, _rootState, rootGetters) => slide => {
    if (slide?.layout_type === 'serviceConfiguration') {
      const hasHostingVoucher = rootGetters['vouchers/voucherByProductCategory']('business-website-hosting')
      const hasEmailVoucher = rootGetters['vouchers/voucherByProductCategory']('business-email')
      const hasDomainVoucher = rootGetters['vouchers/voucherByProductCategory']('business-domain')
      const hasSSLVoucher = rootGetters['vouchers/unRedeemedVoucherByProductCategory']('domain-ssl')
      if (slide.layout_sub_type === 'domain-ssl') {
        // Only show the SSL configuration slide if they bought SSL without hosting
        return hasSSLVoucher && !hasHostingVoucher
      } else if(slide.layout_sub_type === 'business-domain') {
        return hasEmailVoucher || hasHostingVoucher || hasDomainVoucher
      } else {
        // Show web hosting slide if hosting voucher exists or email voucher and standalone hosting request.
        const emailAndHostingRequest = hasEmailVoucher && rootGetters['domains/standaloneHostingInfo']
        if (slide.layout_sub_type === 'business-website-hosting' && (hasHostingVoucher || emailAndHostingRequest)) {
          return true
        }

        return rootGetters['vouchers/unRedeemedVoucherByProductCategory'](slide.layout_sub_type)
      }
    } else {
      return true
    }
  },
  filterHideForManagementType: (_state, getters) => slide => {
    if (!slide.hide_for_management_type?.length) return true

    const matchingManagementType = (managementType) => {
      return slide?.hide_for_management_type.includes(managementType) &&
        (
          getters.company?.management_type ||
          getters.company?.details?.management_type
        )?.toLowerCase() === managementType
    }

    const hasMemberStructure = (details) => {
      if (getters.company?.llc_member_structure) return getters.company?.llc_member_structure
      if (!details) return ''

      const membersCount = details['official.member']?.filter(of => of['first_name'])?.length

      if (!membersCount) return ''
      else return membersCount === 1 ? 'single-member' : 'multi-member'
    }

    return slide.hide_for_management_type?.length ?
      !(matchingManagementType('manager managed') ||
        (matchingManagementType('member managed') && hasMemberStructure(getters.company?.details))) :
      true
  },
  filterSlideBySlideCheckoutType: (_state, getters) => slide => {
    if (slide.layout_type !== 'verifyCheckout') return true

    switch (slide.layout_sub_type) {
      case 'without-cart':
        return getters.cartEmpty
      case 'with-cart':
        return !getters.cartEmpty
      default:
        return true
    }
  },
  filterSlideBySlideProducts: (_state) => slide => {
    return ['product', 'filingOptions', 'productOptions'].includes(slide.layout_type) ?
      !!slide.products.length :
      true
  },
  filterSlideBySelectedProductType: (state, getters, _rootState, rootGetters) => (slide, stage) => {
    if (!slide.show_for_selected_product_types.length) return true

    let stageSelectedProductType = getters.stageProductSelectedType || stage?.data?.selected_product_type

    // Need to check for CM signups who started the premium document so they can resume progress in stageline
    const cmSelectedStageProductType = getters.captureMonsterSelectedStageProductType(slide)
    if (!stageSelectedProductType && cmSelectedStageProductType) {
      stageSelectedProductType = cmSelectedStageProductType
    }

    // Check for companies who purchased the product outside of stageline and don't have this set
    // This allows us to be able to filter slides by product type or show for filing
    if (!stageSelectedProductType && slide.show_for_filings?.length) {
      return getters.filterShowForFiling(slide, stage)
    }

    // Check to see if formation product is in the cart to account for cases where the formation
    // was added to the cart outside of the filing options slide
    if (!stageSelectedProductType && slide.show_for_selected_product_types.includes('form a company')) {
      return rootGetters['checkout/formationProductInCart']
    }

    return !!slide.show_for_selected_product_types.includes(stageSelectedProductType)
  },

  /* ======== miscellaneous ===================================================================== */
  diySlideProductSelected: (_state, getters) => slideProduct => {
    return getters.stageProductSelectedType === 'diy' && slideProduct.product_kind === 'diy'
  },
  ghostMode:            state => state.ghostMode, // Allows the admin to navigate through Stageline without changing any client data
  lastVisitedStage:     (_state, getters) => {
    return getters.currentPeriodStages.sort((a, b) => {
      if (a.last_visited === null && b.last_visited === null) {
        return 0 // Preserve the original order for null values
      } else if (a.last_visited === null) {
        return 1 // Move null values to the end
      } else if (b.last_visited === null) {
        return -1 // Move null values to the end
      } else {
        return b.last_visited - a.last_visited // Sort by descending order
      }
    })[0]
  },
  navigationDisabled:   (_state, getters, _rootState, rootGetters) => {
    return ['checkout', 'verifyCheckout'].includes(getters.currentSlideLayoutType) &&
      !!rootGetters['checkout/processingCheckout']
      || !getters.stagelineLoaded
  },
  productSlideProducts: (state, getters, _rootState, rootGetters) => {
    if (rootGetters['companies/hasExistingRAServiceInJurisdiction'](getters.company.domestic_registration?.jurisdiction)) {
      return state.productSlideProducts.filter(p => p.product_category !== 'free form a company')
    } else {
      return state.productSlideProducts
    }
  },
  showAdminTools:       () => Cookies.get('admin') === 'logged_in_as_client' && sessionStorage.getItem('admin-logged-in'),
  slideProductInCart:   (_state, _getters, _rootState, rootGetters) => slideProduct => {
    const product = slideProduct?.product
    if (!product) return false

    const productInCart = rootGetters['checkout/findCartItem'](product.id)
    const productInCartBundle = product?.product_bundle_override?.id ?
      rootGetters['checkout/findCartItem'](product.product_bundle_override.id) :
      false
    return productInCart || productInCartBundle
  },
  slideProductSelected: (_state, getters) => slideProduct => {
    return !!getters.slideProductInCart(slideProduct) ||
      !!getters.diySlideProductSelected(slideProduct)
  },
  stageHasIncompleteConfigurationSlides: (state, getters) => (stageName) => {
    const stage = getters.currentPeriodStages.find(
      stage => stage.name === stageName
    )

    if (!stage) return false

    return !!getters.currentPeriodSlides.find(
      slide =>
        slide.step.stage.id === stage?.id &&
        slide.completed_at === null &&
        slide.layout_type === 'serviceConfiguration'
    )
  },
  stepHasUnsupportedDocumentSlide:      (_state, getters) => step => {
    const documentSlide = step.slides.find(slide => slide.layout_type === 'document' &&
      !getters.lazyLoadedDocType.includes(slide.layout_sub_type))
    return documentSlide ? 'agency_resource' in documentSlide : true
  },
}

const ACTIONS = {
  addProductSlideProduct({ commit }, slideProduct) {
    commit(t.ADD_PRODUCT_SLIDE_PRODUCT, slideProduct)
  },
  async adminManuallySetComplete({ getters, commit }, { category, id, value }) {
    const period = getters.periods.find(period => period.id === getters.currentSlide.step.stage.period.id)

    const stagelineContext = {
      period_id: period.id,
      completed_by: 'admin',
      [`${category}_id`]: id,
      [`${category}_complete`]: value,
    }

    await http.post(`client/stageline/${getters.company.id}/update_company_stageline_completed`, {
      ...stagelineContext,
    })

    const stage = period.stages.find(stage => stage.id === getters.currentSlide.step.stage.id)
    const step = stage.steps.find(step => step.id === getters.currentSlide.step.id)
    const slide = step.slides.find(slide => slide.id === getters.currentSlide.id)

    if (category === 'slide') slide.completed_at = value ? Date.now() : null
    if (category === 'step') step.completed_at = value ? Date.now() : null
    if (category === 'stage') stage.completed_at = value ? Date.now() : null

    commit(t.SET_PERIOD_DEEP, period)
  },
  async adminManuallySetLastVisited({ getters, commit }, { value }) {
    await http.post(`client/stageline/${getters.company.id}/update_company_stageline_last_visited`, {
      slide_last_finished: value,
      slide_id: getters.currentSlide?.id,
      period_id: getters.currentPeriod?.id,
    })

    const period = getters.periods.find(period => period.id === getters.currentSlide.step.stage.period.id)
    const stage = period.stages.find(stage => stage.id === getters.currentSlide.step.stage.id)
    const step = stage.steps.find(step => step.id === getters.currentSlide.step.id)
    const slide = step.slides.find(slide => slide.id === getters.currentSlide.id)

    slide.last_visited = value ? Date.now() : null

    commit(t.SET_PERIOD_DEEP, period)
  },
  async asyncSetCurrentField({ commit, dispatch }, field) {
    commit(t.SET_STARTING_FIELD, '')
    commit(t.SET_CURRENT_FIELD, field)
    await dispatch('updateSlideProgress')
  },
  completeFirstTwoFormTheCompanyStepsIfFormationStatusIsCompleted({ getters }) {
    // TODO [STAG-2480] extract to stagelineStart store
    const formTheCompanyStage = getters.periods[0]?.stages.find(stage => stage.name === 'form_the_company')
    if (getters.currentPeriod.name !== 'start' || !formTheCompanyStage) return

    if (getters.formationFiling?.status === 'completed') {
      const stepsToComplete = ['form_the_company__fill_out_form', 'form_the_company__verify_and_file']
      formTheCompanyStage.steps.forEach(step => {
        if (stepsToComplete.includes(step.name) && !step.completed_at) step.completed_at = Date.now()
      })
    }
  },
  completeFormTheCompanyStageIfCurrentCompanyFormedElsewhere({ getters, rootGetters }) {
    // TODO [STAG-2480] extract to stagelineStart store
    const formTheCompanyStage = getters.periods[0]?.stages.find(stage => stage.name === 'form_the_company')
    if (getters.currentPeriod.name !== 'start' || !formTheCompanyStage) return

    if (rootGetters['companies/formedElsewhere'] && !formTheCompanyStage.completed_at) {
      formTheCompanyStage.completed_at = Date.now()
      formTheCompanyStage.steps.forEach(step => {
        if (!step.completed_at) step.completed_at = Date.now()
      })
    }
  },
  completeEinStepAndSlidesIfActiveEinProduct({ getters, _rootState, rootGetters }) {
    // TODO [STAG-2609] Refactor to remove reliance on hardcoded names
    const einStage = getters.periods[0].stages.find(stage => stage.name === 'ein')
    if (getters.currentPeriod.name !== 'start' || !rootGetters['companies/einFiling'] || !einStage) return

    const stepNames = ['ein__intro', 'ein__apply']
    const einSteps = einStage.steps.filter(step => stepNames.includes(step.name))

    const completeElement = element => {
      if (!element.completed_at) element.completed_at = Date.now()
    }

    einSteps.forEach(step => {
      completeElement(step)
      step.slides.forEach(slide => completeElement(slide))
    })
  },
  async completePeriod({ commit, getters }, periodId) {
    await http.post(`client/stageline/${getters.company.id}/complete_company_period`, {
      period_id: periodId,
    })

    const periods = getters.periods.map(p => {
      if (p.id === periodId) p.completed_at = Date.now()
      return p
    })

    commit(t.SET_PERIODS, periods)
  },
  async completeStage({ getters, commit }, stageIdOrName) {
    const stage_ = getters.getStageByIdOrName(stageIdOrName)
    const period = getters.currentPeriod
    const stage = period.stages.find(stage => stage.id === stage_.id)

    // Consciously completed by client, so mark it as complete (but still double-check).
    if (stage.completed_at) stage.completed_at = Date.now()

    stage.steps.forEach(step => {
      // If step has not been completed by client yet, automatically "fill in" as completed by system.
      // Client may technically never have seen it.
      if (!step.completed_at) step.completed_at = Date.now()

      // If slide has not been completed by client yet, automatically "fill in" as completed by system.
      // Client may technically never have seen it.
      step.slides.forEach(slide => {
        if (!slide.completed_at) slide.completed_at = Date.now()
      })
    })

    commit(t.SET_PERIOD_DEEP, period)

    const stagelineContext = {
      period_id: period.id,
      stage_id: stage.id,
      stage_complete: !!stage.completed_at,
      completed_by: 'system',
    }

    await http.post(`client/stageline/${getters.company.id}/update_company_stageline_completed`, {
      ...stagelineContext,
    })
  },
  async completeStageForRedirect({ getters }, stageIdOrName) {
    const stage_ = getters.getStageByIdOrName(stageIdOrName)
    const period = getters.currentPeriod
    const stage = period.stages.find(stage => stage.id === stage_.id)

    await http.post(`client/stageline/${getters.company.id}/update_company_stage_completed`, {
      stage_name: stage.name,
      period_id: period.id,
    })
  },
  async completeStageUntilSlide({ getters, commit }, slideIdOrName) {
    const endpointSlide = getters.getSlideByIdOrName(slideIdOrName)
    const period = getters.currentPeriod
    const stage = period.stages.find(stage => stage.id === endpointSlide.step.stage.id)

    const slideIds = []
    let foundEndpointSlide = false
    stage.steps.forEach(step => {
      if (!foundEndpointSlide) {
        step.slides.forEach(slide => {
          if (slide.id === endpointSlide.id) foundEndpointSlide = true
          if (!foundEndpointSlide) {
            if (!slide.completed_at) {
              slideIds.push(slide.id)
              slide.completed_at = Date.now()
            }
          }
        })
        if (step.slides.every(slide => slide.completed_at)) {
          if (!step.completed_at) step.completed_at = Date.now()
        }
      }
    })

    commit(t.SET_PERIOD_DEEP, period)

    const stagelineContext = {
      period_id: period.id,
      slide_ids: slideIds,
      completed_by: 'system',
    }

    await http.post(`client/stageline/${getters.company.id}/update_company_stageline_completed`, {
      ...stagelineContext,
    })
  },
  async completeStageline({ getters, dispatch }) {
    await dispatch('completePeriod', getters.currentPeriod.id)

    await router.push({
      name: 'stageline-v2-end-page',
    }).catch(()=>{})
  },
  completeStagesStepsAndSlides({ getters, dispatch }) {
    if (getters.currentPeriodName === 'start' && getters.company) {
      dispatch('completeFormTheCompanyStageIfCurrentCompanyFormedElsewhere')
      dispatch('completeFirstTwoFormTheCompanyStepsIfFormationStatusIsCompleted')
      dispatch('completeEinStepAndSlidesIfActiveEinProduct')
    }
  },
  async fetchSlideInPeriod({ commit, getters, dispatch }, { slideId, companyId, periodId }) {
    commit(t.SET_STAGELINE_LOADED, false)
    commit(t.SET_SLIDE_LOADED, false)

    await dispatch('companies/setCurrentCompany', { id: companyId, force: false }, { root: true })
    if (!getters.periodDeepLoaded(periodId)) await dispatch('loadPeriodDeep',  { periodIdOrName: periodId })

    const period = getters.periods.find(p => p.id === periodId)

    commit(t.SET_CURRENT_PERIOD_NAME, period.name)
    await dispatch('goToSlide', slideId)
    commit(t.SET_STAGELINE_LOADED, true)
  },
  async getAccountCompaniesSimple({ commit }) {
    // TODO [STAG-2482] Move to Companies store
    const response = await http.get('client/companies/index_simple', { params: { limit: 1000 } })
    if (response.data.result.length) commit(t.SET_ACCOUNT_COMPANIES, response.data.result)
  },
  async getAccountCompanies({ commit }) {
    // TODO [STAG-2482] Move to Companies store
    const response = await http.get('client/companies', { params: { limit: 1000 } })
    if (response.data.result) commit(t.SET_ACCOUNT_COMPANIES, response.data.result)
  },
  async goToFirstIncompleteSlideOfNextIncompleteStage({ getters, dispatch }) {
    const stageId = getters.currentPeriodStages.slice(getters.currentStageIndex).find(stage => stage.completed_at === null)?.id

    if (stageId) {
      const slideId =  getters.firstIncompleteSlideId({ type: 'stage', typeId: stageId })
      dispatch('goToSlide', slideId)
    }
    else {
      await dispatch('completeStageline')
    }
  },
  goToFirstIncompleteSlideOfPreviousStage({ getters, dispatch }) {
    const targetStage = getters.currentPeriodStages[getters.currentStageIndex - 1]
    const slideId =  getters.firstIncompleteSlideId({ type: 'stage', typeId: targetStage.id })
    dispatch('goToSlide', slideId)
  },
  goToFirstIncompleteSlideOfCurrentStage({ getters, dispatch }) {
    const stageId = getters.currentStage.id
    const slideId =  getters.firstIncompleteSlideId({ type: 'stage', typeId: stageId })
    dispatch('goToSlide', slideId)
  },
  goToFirstIncompleteSlideOfStageById({ getters, dispatch }, stageId) {
    const slideId =  getters.firstIncompleteSlideId({ type: 'stage', typeId: stageId })
    dispatch('goToSlide', slideId)
  },
  goToNextSlide({ getters, dispatch }) {
    dispatch('goToSlide', getters.currentPeriodSlides[getters.currentSlidePeriodIndex + 1].id)
  },
  goToPdfSlideInCurrentStage({ getters, dispatch }) {
    const stageId = getters.currentStage.id
    const slideId = getters.currentPeriodSlides.find(
      slide => slide.step.stage.id === stageId && slide.layout_type === 'document'
    ).id

    dispatch('goToSlide', slideId)
  },
  goToSlideInCurrentStageBySlideType({ getters, dispatch }, slideType) {
    const stageId = getters.currentStage.id
    const slideId = getters.currentPeriodSlides.find(
      slide => slide.step.stage.id === stageId && slide.layout_type === slideType
    ).id

    dispatch('goToSlide', slideId)
  },
  goToPeriod({ commit, getters, dispatch }, periodIdOrName) {
    const period = getters.filteredPeriods.find(period => [period.id, period.name].includes(periodIdOrName))
    commit(t.SET_CURRENT_PERIOD_NAME, period.name)
    dispatch('goToSlide', getters.currentPeriodSlides[0])
  },
  goToPreviousSlide({ getters, dispatch }) {
    dispatch('goToSlide', getters.currentPeriodSlides[getters.currentSlidePeriodIndex - 1].id)
  },
  goToSlide({ commit, getters, dispatch }, slideIdOrName) {
    const forceNavigableSlides = ['llc_company_structure__company_structure']

    commit(t.SET_STAGELINE_LOADED, false)
    commit(t.SET_SLIDE_LOADED, false)

    let slide = getters.getSlideByIdOrName(slideIdOrName)
    if (!slide && forceNavigableSlides.includes(slideIdOrName)) {
      slide = getters.filteredPeriodSlideList(getters.currentPeriodName, true).find(s => s.name === slideIdOrName)
    } else if (!slide) {
      dispatch('goToFirstIncompleteSlideOfStageById', getters.lastVisitedStage.id)
    }

    if (slide) {
      dispatch('setCurrentSlide', { slide })
      // TODO might be able to move these two lines to getters
      commit(t.SET_PROCESS_FILING_OBJECTS, slide?.process_filing_objects || [])
      commit(t.SET_SLIDE_PROGRESS, slide?.progress || {})
      // TODO might be cleaner to move this up the call stack after goToSlide gets called
      dispatch('setCompanyStageData')
      commit(t.SET_SLIDE_LOADED, true)
      commit(t.SET_STAGELINE_LOADED, true)
    }
  },
  async goToStagelineRoute({ commit, getters, dispatch }, route) {
    const periodId = route.period_id,
      destinationType = route.destination_type,
      destinationId = route.destination_id

    if (!getters.periodDeepLoaded(periodId)) await dispatch('loadPeriodDeep', { periodIdOrName: periodId })
    const periodName = getters.periods.find(p => p.id === periodId).name
    commit(t.SET_CURRENT_PERIOD_NAME, periodName)

    if (destinationType === 'Stageline::Slide') {
      const destinationSlide = getters.filteredPeriodSlideList(periodName).find(slide => slide.id === destinationId)

      if (!destinationSlide) {
        dispatch('goToFirstIncompleteSlideOfStageById', getters.lastVisitedStage.id)
        return
      } else if (destinationSlide?.name === 'form_the_company__filing_status__pending_order') {

        // TODO: just completing the steps on the frontend for now. delete when mass-completion backend call exists
        const period = getters.periods.find(period => period.id === destinationSlide.step.stage.period.id)
        commit(t.SET_PERIOD_DEEP, period)

        // TODO: after new mass-completion backend call is made actually complete all the slides from these steps with the new call
        // TODO: ~ await dispatch('completeThese', { by: 'system', slides: slideIdArray, steps: stepIdArray })
      }
    }

    switch (destinationType) {
      case 'Stageline::Period': dispatch('goToPeriod', destinationId); break
      case 'Stageline::Stage':  dispatch('goToStage', destinationId); break
      case 'Stageline::Step':   dispatch('goToStep', destinationId); break
      case 'Stageline::Slide':  dispatch('goToSlide', destinationId); break
    }
  },
  goToStage({ getters, dispatch }, stageIdOrName) {
    dispatch('goToSlide', getters.currentPeriodSlides.find(slide => [slide.step.stage.id, slide.step.stage.name].includes(stageIdOrName))?.id)
  },
  goToStep({ getters, dispatch }, stepIdOrName) {
    dispatch('goToSlide', getters.currentPeriodSlides.find(slide => [slide.step.id, slide.step.name].includes(stepIdOrName))?.id)
  },
  async goToVerifyOrderSlide({ getters, dispatch }) {
    if (getters.currentPeriodName !== 'start') await dispatch('loadPeriod', 'start')

    const slide = getters.getSlideByLayoutType('verifyOrder')
    if (slide.completed_at) return
    dispatch('goToSlide', slide.id)
  },
  async initializeStageline({ getters, dispatch }) {
    if (!getters.periods?.length) await dispatch('loadPeriods')

    const stagelineRoute    = getters.companyStagelineRoute
    let lastVisitedSlide    = _.mapKeys(getters.company?.stageline?.last_visited_slide, (v, k) => _.camelCase(k))
    const incompletePeriods = getters.periods.filter(p => !p.completed_at)

    const periodName = incompletePeriods.find(p => p.id === stagelineRoute?.period_id)?.name
      || incompletePeriods.find(p => p.id === lastVisitedSlide?.period_id)?.name || 'start'

    if (periodName) await dispatch('setCurrentPeriodName', periodName)
  },
  async loadPeriodDeep({ commit, getters, dispatch }, { periodIdOrName }) {
    // TODO set a mutation showing that the request is processing, and return if so

    // if (!force && getters.periodDeepLoaded(periodIdOrName)) {
    //   return
    // }

    // if (!getters.periods.length) {
    //   dispatch('loadPeriods')
    // }

    let period = getters.periods.find(p => [p.id, p.name].includes(periodIdOrName))

    if (!period) {
      await dispatch('loadPeriods')
      period = getters.periods.find(p => [p.id, p.name].includes(periodIdOrName))
    }

    const response = await http.get(`client/stageline/${getters.company.id}/periods/${period.id}`)
    if (response.data.success) {
      const period = response.data.result
      commit(t.SET_PERIOD_DEEP, period)
    }
  },
  async loadPeriods({ commit, getters }) {
    commit(t.SET_PERIODS_LOADED, false)
    commit(t.CLEAR_PERIODS)

    const response = await http.get(`client/stageline/${getters.company.id}/periods`)
    const periods = response.data.result

    if (response.data.success) commit(t.SET_PERIODS, periods)

    commit(t.SET_PERIODS_LOADED, true)
  },
  async loadPeriod({ commit, getters, dispatch }, periodName) {
    commit(t.SET_STAGELINE_LOADED, false)
    commit(t.SET_SLIDE_LOADED, false)

    if (!getters.periods.length) await dispatch('loadPeriods')
    if (!getters.periodDeepLoaded(periodName)) await dispatch('loadPeriodDeep', { periodIdOrName: periodName })

    commit(t.SET_CURRENT_PERIOD_NAME, periodName)

    if (periodName === 'start') dispatch('setDecisionTreeTiles')

    commit(t.SET_STAGELINE_LOADED, true)
  },
  async loadResourceByType({ getters, commit }) {
    const response = await http.get(`client/stageline/${getters.company.id}/load_resource/${getters.currentSlide.id}`)
    if (response.data.success) commit(t.SET_SLIDE_AGENCY_RESOURCE, response.data.result)
  },
  async logSlideInteraction({ getters }, interaction) {
    if (getters.ghostMode) return

    await http.post(`client/stageline/${getters.company.id}/log_slide_interaction`, {
      period_id: getters.currentPeriod.id,
      slide_id: getters.currentSlide.id,
      interaction: interaction,
    })
  },
  async navigateToAndCompleteStageSlidesBeforeEndpoint({ dispatch }, slideIdOrName) {
    // Any time this method is call, be sure that you are removing the client from stageline context
    // Ex: directing them to websites tab or documents tab
    await dispatch('completeStageUntilSlide', slideIdOrName)
    await dispatch('goToSlide', slideIdOrName)
  },
  navigateToStage({ getters, dispatch }, stage) {
    const slideId = stage.completed_at ? stage.steps[0].slides[0].id :
      getters.firstIncompleteSlideId({ type: 'stage', typeId: stage.id })
    dispatch('goToSlide', slideId)
  },
  navigateToStageByName({ getters, dispatch }, stageName) {
    const stage = getters.getStageByIdOrName(stageName)
    if (stage) dispatch('navigateToStage', stage)
  },
  async saveResourceToAccount({ getters }, fields) {
    return await http.post(`client/stageline/${getters.company.id}/save_resource_to_account/${getters.currentSlide.id}`, { fields })
  },
  async setObjectIdAndField({ dispatch, commit }, { objectId, startingField }) {
    dispatch('setCurrentObjectId', objectId)
    commit(t.SET_CURRENT_FIELD, '')
    commit(t.SET_STARTING_FIELD, startingField)
  },
  async setCurrentObjectId({ commit, dispatch }, objectId) {
    commit(t.SET_OBJECT_ID, objectId)
    dispatch('updateSlideProgress')
  },
  setCurrentPeriodName({ commit }, periodName) {
    commit(t.SET_CURRENT_PERIOD_NAME, periodName)
  },
  setDecisionTreeTiles({ commit, getters }) {
    // TODO [STAG-2481] only applicable to stagelineForm store
    const stages = cloneDeep(getters.currentPeriodStages)
    const decisionTiles = stages.map(stage => {
      const steps = stage?.steps
      if (steps?.length) steps.splice(1)
      const slides = steps[0]?.slides
      if (slides?.length) slides.splice(1)
      return stage
    })
    commit(t.SET_DECISION_TILES, decisionTiles)
  },
  setJurisdiction({ commit }, jurisdiction) {
    commit(t.SET_JURISDICTION, jurisdiction)
  },
  setSlideLoaded({ commit }, loaded) {
    commit(t.SET_SLIDE_LOADED, loaded)
  },
  setCurrentField({ commit, dispatch }, field) {
    commit(t.SET_STARTING_FIELD, '')
    commit(t.SET_CURRENT_FIELD, field)
    dispatch('updateSlideProgress')
  },
  async setProductType({ dispatch }, type) {
    await dispatch('updateCompanyStageData', { "selected_product_type": type })
  },
  setCurrentSlide({ dispatch, commit }, { slide, updateLastVisited = true }) {
    if (updateLastVisited) dispatch('updateLastVisited', slide)
    commit(t.SET_CURRENT_SLIDE, slide)
    commit(t.SET_SLIDE_AGENCY_RESOURCE, slide?.agency_resource || null)
  },
  setGhostMode({ commit }, value) {
    commit(t.SET_GHOST_MODE, value)
  },
  setCaptureMonsterSelectedProductType({ getters, dispatch }, slide) {
    const type = getters.captureMonsterSelectedStageProductType(slide)
    dispatch('setProductType', type)
  },
  setCompanyStageData({ commit, getters }) {
    let stageData = getters.currentStage?.data
    // Legal docs handles the updating of stage data, dont let setCurrentSlide overwrite this
    // unless uninitialized
    const stagesToDefaultToCompanyStageData = ['form_the_company', 'finalize_legal_documents', 'business_idea_and_plan', 'ein']

    if (getters.currentStage && stagesToDefaultToCompanyStageData.includes(getters.currentStage?.name))
      stageData = getters.companyStageData || getters.currentStage?.data

    commit(t.SET_COMPANY_STAGE_DATA, stageData)
  },
  setShowEndOfStageSlide({ commit }, value) {
    commit(t.SET_SHOW_END_OF_STAGE_SLIDE, value)
  },
  updateLastVisited({ getters, commit }, slide) {
    if (getters.ghostMode || !slide) return
    const period= getters.periods.find(period => period.id === slide.step.stage.period.id)
    const stage = period.stages.find(stage => stage.id === slide.step.stage.id)
    const step      = stage.steps.find(step => step.id === slide.step.id)
    const slide_    = step.slides.find(slide => slide.id === slide.id)

    // Update locals immediately
    period.last_visited = Date.now()
    stage.last_visited = Date.now()
    step.last_visited = Date.now()
    slide_.last_visited = Date.now()
    commit(t.SET_PERIOD_DEEP, period)

    const stagelineContext = {
      slide_id: slide.id,
      step_id: slide.step.id,
      stage_id: slide.step.stage.id,
      period_id: slide.step.stage.period.id,
    }

    http.post(`client/stageline/${getters.company.id}/update_company_stageline_progress`, stagelineContext)
  },
  async updateSlideProgress({ state, commit, getters }, name = '') {
    if (getters.ghostMode) return
    if (name) Vue.set(state.progress, name, '')

    const response = await http.post(`client/stageline/${getters.company.id}/update_company_stageline_slide_progress`, {
      period_id: getters.currentPeriod.id,
      slide_id: getters.currentSlide.id,
      progress: getters.progress,
      newProgressTimestampValue: name,
    })

    if (response.data.success) commit(t.SET_SLIDE_PROGRESS, response.data.result.company_slide_progress)
  },
  async updateCompanyStageData({ commit, getters }, data) {
    if (getters.ghostMode) return

    const response = await http.post(`client/stageline/${getters.company.id}/update_company_stageline_stage_data`, {
      period_id: getters.currentPeriod.id,
      stage_id: getters.currentStage.id,
      stage_data: data,
    })

    if (response.data.success) commit(t.SET_COMPANY_STAGE_DATA, response.data.result.company_stage_data)
  },
  async updateCompanyStagelineProgress({ commit, getters }, { details }) {
    if (getters.ghostMode) return

    const period = getters.periods.find(period => period.id === getters.currentSlide.step.stage.period.id)
    const stage = period.stages.find(stage => stage.id === getters.currentSlide.step.stage.id)
    const step = stage.steps.find(step => step.id === getters.currentSlide.step.id)
    const slide = step.slides.find(slide => slide.id === getters.currentSlide.id)

    // Have to update locals immediately before currentSlide changes. If we wait to update after
    // awaiting response, currentSlide will have changed.
    slide.completed_at = Date.now()
    if (getters.slideIsLast) {
      step.completed_at = Date.now()
      if (getters.stepIsLast) {
        stage.completed_at = Date.now()
        if (getters.stageIsLast) period.completed_at = Date.now()
      }
    }
    commit(t.SET_PERIOD_DEEP, period)

    const stagelineContext = {
      completed_by: 'client',
      slide_id: getters.currentSlide.id,
      step_id: getters.currentStep.id,
      stage_id: getters.currentStage.id,
      period_id: getters.currentPeriod.id,
      slide_complete: true,
      step_complete: !!step.completed_at,
      stage_complete: !!stage.completed_at,
      period_complete: !!period.completed_at,
    }

    await http.post(`client/stageline/${getters.company.id}/update_company_stageline_progress`, {
      details: details,
      ...stagelineContext,
    })
  },
  async reloadSlideORA({ commit, getters }) {
    const response = await http.get(`client/stageline/${getters.company.id}/reload_slide_process_filing_objects/${getters.currentSlide.id}`)
    if (response.data.success) commit(t.SET_PROCESS_FILING_OBJECTS, response.data.process_filing_objects)
  },
  resetStageline({ commit }) {
    commit(t.RESET_STAGELINE)
  },
}

const MUTATIONS = {
  [t.RESET_STAGELINE](state) {
    const newState = initialState()

    Object.keys(newState).forEach(key => {
      if(key !== 'accountCompanies')
        state[key] = newState[key]
    })
  },
  [t.SET_PERIODS](state, periods) {
    state.periods = periods
  },
  [t.SET_PERIOD_DEEP](state, period) {
    const index = state.periods.findIndex(p => p.id === period.id)
    Vue.set(state.periods, index, period)
  },
  [t.SET_CURRENT_SLIDE](state, slide) {
    state.currentSlide = slide
  },
  [t.SET_CURRENT_PERIOD_NAME](state, periodName) {
    state.currentPeriodName = periodName
  },
  [t.SET_JURISDICTION](state, jurisdiction) {
    state.jurisdiction = jurisdiction
  },
  [t.CLEAR_PERIODS](state) {
    state.periods = []
  },
  [t.SET_PERIODS_LOADED](state, loaded) {
    state.periodsLoaded = loaded
  },
  [t.SET_SLIDE_LOADED](state, loaded) {
    state.slideLoaded = loaded
  },
  [t.SET_STAGELINE_LOADED](state, loaded) {
    state.stagelineLoaded = loaded
  },
  [t.SET_ACCOUNT_COMPANIES](state, accountCompanies) {
    state.accountCompanies = accountCompanies
  },
  [t.SET_HIDE_NEXT_BUTTON](state, value) {
    state.hideNextButton = value
  },
  [t.SET_PROCESS_FILING_OBJECTS](state, objects) {
    state.processFilingObjects = objects
  },
  [t.SET_STARTING_FIELD](state, field) {
    state.startingField = field
  },
  [t.SET_CURRENT_FIELD](state, field) {
    Vue.set(state.progress, 'current_field', field)
  },
  [t.SET_SLIDE_PROGRESS](state, progress) {
    state.progress = progress
  },
  [t.SET_SLIDE_AGENCY_RESOURCE](state, agencyResource) {
    state.slideAgencyResource = agencyResource
  },
  [t.SET_OBJECT_ID](state, objectId) {
    Vue.set(state.progress, 'current_object_id', objectId)
  },
  [t.SET_COMPANY_STAGE_DATA](state, data) {
    state.companyStageData = data
  },
  [t.SET_DECISION_TILES](state, decisionTiles) {
    state.decisionTiles = decisionTiles
  },
  [t.ADD_PRODUCT_SLIDE_PRODUCT](state, slideProduct) {
    const productSlideProducts = state.productSlideProducts
    Vue.set(state, 'productSlideProducts', productSlideProducts.concat(slideProduct))
  },
  [t.SET_GHOST_MODE](state, value) {
    state.ghostMode = value
  },
  [t.SET_SHOW_END_OF_STAGE_SLIDE](state, value) {
    state.showEndOfStageSlide = value
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
